import EPaymentProvider from "../types/payment.provider.enum";

export default class PaymentIntegrationModel {
  id: string;
  accountId: string;
  createdAt: Date;
  provider: EPaymentProvider;
  accountName: string;
  defaultCurrency: string;
  providerAccountIdentifier: string;

  static fromRequest(params: Partial<PaymentIntegrationModel>) {
    const model = new PaymentIntegrationModel();
    model.initProperties(params);
    return model;
  }

  protected initProperties(params: Partial<PaymentIntegrationModel>) {
    Object.assign(this, params);

    if (params.createdAt) {
      this.createdAt = new Date(params.createdAt);
    }
  }
}
