import { useQuery } from "@tanstack/react-query";

import { useIsAuthenticated } from "../../auth";

import { qkForCurrentUser } from "../query-keys";
import UsersService from "../users.service";

export function useCurrentUserQuery() {
  const isAuthenticated = useIsAuthenticated();

  return useQuery({
    queryKey: qkForCurrentUser(),
    queryFn: () => UsersService.fetchCurrentUser(),
    enabled: isAuthenticated,
  });
}

export default useCurrentUserQuery;
