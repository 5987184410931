export class AccountGeneralStatsModel {
  numberOfPartners: number;
  numberOfLeads: number;
  numberOfSales: number;
  salesRevenue: number;
  salesRevenueShare: number;
  salesProfit: number;

  static fromRequest(params: Partial<AccountGeneralStatsModel>): AccountGeneralStatsModel {
    const model = new AccountGeneralStatsModel();
    Object.assign(model, params);
    return model;
  }
}

export default AccountGeneralStatsModel;
