import { useQuery } from "@tanstack/react-query";

import AccountService from "../account.service";
import { qkForSomeAccounts } from "../query-keys";

export function useAccountsQuery(accountIds: string[]) {
  return useQuery({
    queryKey: qkForSomeAccounts(accountIds),
    queryFn: () => Promise.all(accountIds.map((accountId) => AccountService.fetchAccount(accountId))),
  });
}
