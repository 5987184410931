import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiRequestOptions } from "./types";

import environment from "../../app/environment";
import { AuthSessionTokensModel } from "../../modules/auth";

type ApiDownloadOptions = {
  fallbackFileName: string;
};

export default class ApiService {
  static apiUrl = environment.api.url;
  static authTokens: AuthSessionTokensModel | null;

  headers: { [key: string]: string } = {
    "content-type": "application/json",
  };

  apiVersion = "1";
  suffix = "/api";

  authenticated() {
    return this.withAuthHeader(ApiService.authTokens?.accessToken);
  }

  withSuffix(suffix: string) {
    this.suffix = suffix;
    return this;
  }

  withVersion(version: string) {
    this.apiVersion = version;
    return this;
  }

  withHeader(key: string, value: string) {
    this.headers = {
      ...this.headers,
      [key.toLowerCase()]: value,
    };

    return this;
  }

  withAuthHeader(token: string | undefined) {
    return this.withHeader("Authorization", `Bearer ${token}`);
  }

  async get<T = any>(resource: string, options: ApiRequestOptions = {}): Promise<AxiosResponse<T>> {
    return axios.get(this.getUrl(resource), this.mergeOptions(options));
  }

  async put<T = any>(resource: string, data?: unknown, options: ApiRequestOptions = {}): Promise<AxiosResponse<T>> {
    return axios.put(this.getUrl(resource), data, this.mergeOptions(options));
  }

  async patch<T = any>(resource: string, data?: unknown, options: ApiRequestOptions = {}): Promise<AxiosResponse<T>> {
    return axios.patch(this.getUrl(resource), data, this.mergeOptions(options));
  }

  async post<T = any>(resource: string, data?: unknown, options: ApiRequestOptions = {}): Promise<AxiosResponse<T>> {
    return axios.post(this.getUrl(resource), data, this.mergeOptions(options));
  }

  async delete<T = any>(resource: string, data?: unknown, options: ApiRequestOptions = {}): Promise<AxiosResponse<T>> {
    return axios.request({
      data,
      method: "DELETE",
      url: this.getUrl(resource),
      ...this.mergeOptions(options),
    });
  }

  async download(resource: string, options?: ApiDownloadOptions): Promise<void> {
    const response = await this.get(resource, { responseType: "blob" });

    const contentDisposition: string | undefined = response.headers["content-disposition"];
    const fileName =
      contentDisposition?.match(/;\s*filename="([^"]*)"/)?.[1] || options?.fallbackFileName || "download";

    const bodyUrl = URL.createObjectURL(response.data);
    try {
      const link = document.createElement("a");
      link.href = bodyUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      try {
        link.click();
      } finally {
        document.body.removeChild(link);
      }
    } finally {
      URL.revokeObjectURL(bodyUrl);
    }
  }

  private getUrl(resource: string) {
    const resourceWithPrefixAndVersion = `${this.suffix}/v${this.apiVersion}${resource}`;
    return new URL(resourceWithPrefixAndVersion, ApiService.apiUrl).toString();
  }

  private mergeOptions(options: AxiosRequestConfig) {
    return {
      ...options,
      headers: {
        ...this.headers,
        ...(options.headers || {}),
      },
    } as AxiosRequestConfig;
  }
}
