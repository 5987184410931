import { createContext } from "react";

import { UserRole } from "../types";

export type UserRoleContextType = {
  userRole: UserRole | null;
  setUserRole: (UserRoleAuth: UserRole | null) => void;
};

export const UserRoleContext = createContext<UserRoleContextType>({
  userRole: null,
  setUserRole() {
    // Do nothing
  },
});

export default UserRoleContext;
