import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { EUserRoleType } from "../../../../modules/users/enums";
import { useCurrentUserRole } from "../../../../modules/users/hooks";

import { DefaultLoader } from "../../../themed";

type Props = { roleType: EUserRoleType } | { roleTypes: EUserRoleType[] };

export function UserRoleGate(props: Props) {
  const allowedRoleTypes = "roleType" in props ? [props.roleType] : props.roleTypes;

  const navigate = useNavigate();
  const currentRole = useCurrentUserRole();

  const loading = !currentRole;
  const allowed = !loading && allowedRoleTypes.includes(currentRole.type);

  useEffect(() => {
    if (!loading && !allowed) {
      navigate("/", { replace: true });
    }
  }, [loading, allowed]);

  if (allowed) {
    return <Outlet />;
  } else {
    return <DefaultLoader />;
  }
}

export default UserRoleGate;
