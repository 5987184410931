import { useContext } from "react";

import { UserRoleContext } from "../contexts";
import { UserRole } from "../types";

export function useCurrentUserRole(): UserRole | null {
  const { userRole } = useContext(UserRoleContext);

  return userRole;
}

export default useCurrentUserRole;
