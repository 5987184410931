import { OptionsEnum, OptionSelectorTypesEnum } from "../enums";

export class OptionConfiguration {
  key: OptionsEnum;
  selectorType: OptionSelectorTypesEnum = OptionSelectorTypesEnum.Default;
  selectorValue = "";

  static fromRequest(params: Partial<OptionConfiguration>) {
    const model = new OptionConfiguration();
    Object.assign(model, params);

    return model;
  }

  setSelectorType(type: OptionSelectorTypesEnum) {
    if (type === OptionSelectorTypesEnum.Default) {
      this.selectorValue = "";
    }

    this.selectorType = type;
  }

  setSelectorValue(value: string) {
    this.selectorValue = value;
  }

  isValid() {
    if (this.selectorType === OptionSelectorTypesEnum.Default) {
      return this.selectorValue === "";
    }

    switch (this.selectorType) {
      case OptionSelectorTypesEnum.Id:
        return /^#[A-Za-z]+[\w\-:.]*$/.test(this.selectorValue);
      case OptionSelectorTypesEnum.Class:
        return /\.-?[_a-zA-Z]+[_a-zA-Z0-9-]*/.test(this.selectorValue);
      default:
        return this.selectorValue !== "";
    }
  }

  isDefault() {
    return this.selectorType === OptionSelectorTypesEnum.Default;
  }
}
