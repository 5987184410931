export enum EEnvironment {
  Local = "local",
  Jamie = "jamie",
  Staging = "staging",
  Production = "production",
}

export type Environment = {
  type: EEnvironment;
  api: {
    url: string;
  };
  linksDomain: {
    custom: string;
    default: string;
  };
};
