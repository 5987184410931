export class LeadGeneralStatsModel {
  numberOfSales: number;
  salesRevenue: number;
  salesRevenueShare: number;
  salesProfit: number;

  static fromRequest(params: Partial<LeadGeneralStatsModel>): LeadGeneralStatsModel {
    const model = new LeadGeneralStatsModel();
    Object.assign(model, params);
    return model;
  }
}

export default LeadGeneralStatsModel;
