import { useQuery } from "@tanstack/react-query";

import StatsService from "../../stats/stats.service";

import { qkForAccountStats } from "../query-keys";

export function useAccountStatsQuery(accountId: string) {
  return useQuery({
    enabled: Boolean(accountId),
    queryKey: qkForAccountStats(accountId),
    queryFn: () => StatsService.fetchAccountStats(accountId),
  });
}
