export enum RelativeDateRange {
  LastSevenDays = "last-seven-days",
  LastMonth = "last-month",
  LastThreeMonths = "last-three-months",
  LastSixMonths = "last-six-months",
  LastQuarter = "last-quarter",
  LastYear = "last-year",
  MonthToDate = "month-to-date",
  QuarterToDate = "quarter-to-date",
  YearToDate = "year-to-date",
}

export type AbsoluteDateRange = [string, string];

export type DateRange = { relativeRange: RelativeDateRange } | { absoluteRange: AbsoluteDateRange };

export default DateRange;
