import { MailingAddress } from "../../common";
import { EWiseBankAccountType, EWiseLegalType } from "../../wise";

export class PartnerPayoutSettingsDepositInfo {
  legalType: EWiseLegalType;
  name: string;
  routingNumber: string;
  accountNumber: string;
  accountType: EWiseBankAccountType;
  address: MailingAddress;

  static fromRequest(details: any) {
    const deposit = new PartnerPayoutSettingsDepositInfo();
    deposit.legalType = details.legalType;
    deposit.name = details.name;
    deposit.routingNumber = details.routingNumber;
    deposit.accountNumber = details.accountNumber;
    deposit.accountType = details.accountType;
    deposit.address = MailingAddress.fromRequest(details.address);
    return deposit;
  }
}

export class PartnerPayoutSettings {
  depositInfo?: PartnerPayoutSettingsDepositInfo;

  get enabled() {
    return Boolean(this.depositInfo);
  }

  static fromRequest(details: any) {
    const settings = new PartnerPayoutSettings();

    if (details.depositInfo) {
      settings.depositInfo = PartnerPayoutSettingsDepositInfo.fromRequest(details.depositInfo);
    }

    return settings;
  }
}
