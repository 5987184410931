import React from "react";
import DefaultLoader from "../../loaders/default.loader";

export type LoadingStateProps = {
  inline?: boolean;
};

export default function LoadingState(props: LoadingStateProps) {
  const { inline = true } = props;

  return <DefaultLoader inline={inline} />;
}
