import React, { PropsWithChildren } from "react";
import styles from "./stats.card.module.scss";
import Card from "../Card";

type Props = PropsWithChildren & {
  title: string;
  primary?: boolean;
  className?: string;
};

export default function StatsCard(props: Props) {
  const { title, primary, children, className } = props;

  return (
    <Card shadow className={`${styles.container} ${primary ? styles.primary : ""} ${className ?? ""}`}>
      <div className={styles.contentText}>{children}</div>
      <div className={styles.titleText}>{title}</div>
    </Card>
  );
}
