import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { AuthSessionContext } from "../contexts";

export function useSignOut() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setAuthSessionTokens } = useContext(AuthSessionContext);

  return useCallback(async () => {
    setAuthSessionTokens(null);
    await queryClient.invalidateQueries({ refetchType: "none" });
    navigate("/auth/sign-in");
  }, [queryClient]);
}

export default useSignOut;
