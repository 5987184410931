import React from "react";

import { Trigger } from "@radix-ui/react-accordion";

import Icon from "../icon";

import styles from "./styles.module.scss";

export const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof Trigger>,
  React.ComponentPropsWithoutRef<typeof Trigger> & { className?: string } // HACK
>((props, forwardedRef) => (
  <Trigger {...props} ref={forwardedRef} className={styles.trigger + " " + (props.className ?? "")}>
    {props.children}
    <Icon name="expand_more" className={styles.chevron} />
  </Trigger>
));

AccordionTrigger.displayName = "AccordionTrigger";

export default AccordionTrigger;
