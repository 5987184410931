import ApiService from "../../../services/api/api.service";

import EPaymentProvider from "./types/payment.provider.enum";
import ConnectOauthAccountDto from "./dto/connect.oauth.account.dto";
import PaymentIntegrationModel from "./models/payment.integration.model";
import DisconnectOauthAccountDto from "./dto/disconnect.oauth.account.dto";

export default class PaymentIntegrationService {
  private static api = new ApiService();

  static fetchPaymentProviders(accountId: string): Promise<PaymentIntegrationModel[]> {
    return this.api
      .authenticated()
      .get(`/accounts/${accountId}/payment-providers`)
      .then((response) => response.data.map(PaymentIntegrationModel.fromRequest));
  }

  static fetchConnectPaymentProviderUrl(accountId: string, provider: EPaymentProvider): Promise<{ url: string }> {
    return this.api
      .authenticated()
      .get(`/accounts/${accountId}/payment-providers/connect-url/${provider}`)
      .then((response) => response.data);
  }

  static connectPaymentProvider(accountId: string, data: ConnectOauthAccountDto): Promise<PaymentIntegrationModel> {
    return this.api
      .authenticated()
      .post(`/accounts/${accountId}/payment-providers/connect`, data)
      .then((response) => PaymentIntegrationModel.fromRequest(response.data));
  }

  static disconnectPaymentProvider(accountId: string, data: DisconnectOauthAccountDto): Promise<void> {
    return this.api.authenticated().delete(`/accounts/${accountId}/payment-providers/disconnect`, data).then();
  }
}
