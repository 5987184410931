import { createChartGradientBackground } from "./helpers";
import { ChartOptions, FillerControllerDatasetOptions, LineControllerDatasetOptions } from "chart.js/dist/types";

export const commonChartOptions: ChartOptions<"line" | "bar"> = {
  plugins: {
    legend: {
      display: false,
    },
    filler: {
      propagate: true,
    },
  },
  elements: {
    line: {
      tension: 0.35,
    },
  },
  interaction: {
    intersect: false,
    mode: "index" as const,
  },
  responsive: true,
  maintainAspectRatio: false,
};

export const lineChartDatasetOptions: Partial<LineControllerDatasetOptions & FillerControllerDatasetOptions> = {
  fill: "start",
  borderWidth: 1,
  borderColor: "rgba(75,207,204,0.65)",
  pointHoverBackgroundColor: "rgba(75,207,204,1)",
  backgroundColor: createChartGradientBackground,
};

export const barChartDatasetOptions = {
  fill: "start",
  borderWidth: 1,
  borderColor: "rgba(75,207,204,0.65)",
  backgroundColor: createChartGradientBackground,
};
