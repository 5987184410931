import React, { ForwardedRef, InputHTMLAttributes, forwardRef, useMemo } from "react";

import styles from "./input.module.scss";

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, "size"> & {
  errorMessage?: string;
  size?: "small" | "default";
  onEnter?: () => void;
};

function Input_(props: Props, ref: ForwardedRef<HTMLInputElement>) {
  const { errorMessage = "", className = "", size, onEnter, onKeyUp: userOnKeyUp, ...rest } = props;

  const hasError = useMemo(() => errorMessage.length !== 0, [errorMessage]);

  return (
    <input
      ref={ref}
      data-size={size}
      data-error={hasError}
      aria-errormessage={errorMessage}
      onKeyUp={
        onEnter
          ? (event) => {
              userOnKeyUp?.(event);
              if (event.key === "Enter") {
                onEnter();
              }
            }
          : userOnKeyUp
      }
      {...rest}
      className={styles.input + " " + className}
    />
  );
}

export default forwardRef(Input_);
