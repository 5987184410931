import { useQuery } from "@tanstack/react-query";

import AccountService from "../account.service";
import { qkForAllAccounts } from "../query-keys";

export function useAllAccountsQuery() {
  return useQuery({
    queryKey: qkForAllAccounts(),
    queryFn: () => AccountService.fetchAllAccounts(),
  });
}
