export class MailingAddress {
  country: string; // 2 char code, lower case
  state: string; // 2 char code, lower case
  city: string;
  addressLine: string;
  zipCode: string;

  isSame(other: MailingAddress): boolean {
    return (
      this.country === other.country &&
      this.state === other.state &&
      this.city === other.city &&
      this.addressLine === other.addressLine &&
      this.zipCode === other.zipCode
    );
  }

  static fromRequest(details: any) {
    const address = new MailingAddress();
    address.country = details.country.toLowerCase();
    address.state = details.state.toLowerCase();
    address.city = details.city;
    address.addressLine = details.addressLine;
    address.zipCode = details.zipCode;
    return address;
  }
}
