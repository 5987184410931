import React, { PropsWithChildren } from "react";

import styles from "./styles.module.scss";

type Props = PropsWithChildren<{
  className?: string;
}>;

export function MutedText(props: Props) {
  const { className, children } = props;

  return <span className={`${styles.mutedText} ${className ?? ""}`}>{children}</span>;
}

export default MutedText;
