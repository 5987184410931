import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useCurrentUserQuery } from "../../../../modules/users";

import { DefaultLoader } from "../../../themed";

export function AuthSessionGate() {
  const navigate = useNavigate();

  // Use `isInitialLoading` instead of `isLoading` so that if the query is disabled (ie. the user is not
  // authenticated), we get `false` (whereas `isLoading` defaults to `true`, even when the query is disabled).
  //
  // We specifically want to know if we need to wait because authentication is being verified, and we want to know if
  // the verification was successful. In all other cases, we want to ask the user to sign in.
  //
  const { isInitialLoading, isSuccess } = useCurrentUserQuery();

  useEffect(() => {
    if (!isInitialLoading && !isSuccess) {
      // TODO: remember where the user was and redirect them to the correct replace after signing in.
      navigate("/auth/sign-in", { replace: true });
    }
  }, [isInitialLoading, isSuccess]);

  if (isSuccess) {
    return <Outlet />;
  } else {
    return <DefaultLoader />;
  }
}

export default AuthSessionGate;
