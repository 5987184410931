import moment from "moment";
import React from "react";

type Props = {
  date: Date;
  className?: string;
};

export function FullDate(props: Props) {
  const { date, className } = props;

  const formattedDate = moment(date).format("MMMM Do, YYYY [at] h:mm a");

  return <span className={className}>{formattedDate}</span>;
}

export default FullDate;
