import React from "react";

import styles from "./default.loader.module.scss";

type Props = {
  inline?: boolean;
  center?: boolean;
  className?: string;
};

export default function DefaultLoader(props: Props) {
  const { inline, center, className } = props;

  return (
    <div data-inline={inline} data-center={center} className={`${styles.container} ${className}`}>
      <div className={styles.ripple}>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
