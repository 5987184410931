import React from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Input from "../../inputs/input";

import "./styles.global.scss";
import styles from "./styles.module.scss";

type Props = ReactDatePickerProps & {
  large?: boolean;
};

export function DatePicker(props: Props) {
  const { large, ...restProps } = props;

  return (
    <div className={large ? styles.large : undefined}>
      <ReactDatePicker enableTabLoop={false} customInput={<Input size="small" readOnly />} {...restProps} />
    </div>
  );
}

export default DatePicker;
