import React from "react";
import styles from "./simple.notification.module.scss";
import { Link } from "react-router-dom";

type Props = {
  text: string;
  link?: string;
  linkText?: string;
};

export default function SimpleNotification(props: Props) {
  const { text, link, linkText } = props;

  return (
    <div className={styles.container}>
      <div className={styles.text}>{text}</div>
      {link && linkText && (
        <Link to={link} className={styles.link}>
          {linkText}
        </Link>
      )}
    </div>
  );
}
