export function cleanDomainName(value: string): string {
  const URL_REGEX = /^(?:\w+\:\/\/)?([^\/]+).*$/;
  const matches = URL_REGEX.exec(value.toLowerCase());

  const returnedValue = matches ? matches[1] : value;

  return returnedValue.trim();
}

export function isValidDomainName(value: string): boolean {
  // This isn't an exhaustive validation, it's more of a sanity check.
  return value.length <= 253 && /^[a-z0-9\-]+(\.[a-z0-9\-]+){1,}$/.test(value);
}
