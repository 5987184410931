import { useAccountCurrencyQuery } from "../../account/hooks/use-account-currency-query";

import { usePartnerQuery } from "./use-partner-query";

export function usePartnerCurrencyQuery(partnerId: string) {
  const partnerQuery = usePartnerQuery(partnerId);

  return useAccountCurrencyQuery(partnerQuery.data?.accountId ?? "");
}

export default usePartnerCurrencyQuery;
