import React, { useState } from "react";

import { useClickAwayListener, useEscapeListener } from "../../../../app/helpers";

import Icon from "../../icon";
import { Picker } from "../../pickers";

import styles from "./styles.module.scss";

// The `allowNone` prop means that `null` is a valid option. We can allow the user to select `null` either by
// presenting a "None" option, or by pressing an already-selected option to deselect it, etc.
//
// There are many cases, however, where the user is required to pick an option, but we don't have a reasonable initial
// value. In this case, the picker can be initialized with `value == null`, but the user isn't then allowed to pick
// "None" as an option. This is why the `value` prop is always allowed to be `null`, even if `allowNone == false`.

type PropsWithoutNone<T> = {
  options: T[];
  getOptionKey?: (option: T) => string;
  getOptionLabel?: (option: T) => React.ReactNode;

  value: T | null;
  onChange?: (value: T) => void;

  allowNone?: false;
  placeholder?: React.ReactNode;

  isLoading?: boolean;
  hasError?: boolean;
  disabled?: boolean;
  className?: string;
};

type PropsWithNone<T> = Omit<PropsWithoutNone<T>, "onChange" | "allowNone"> & {
  onChange?: (value: T | null) => void;
  allowNone: true;
};

type Props<T> = PropsWithoutNone<T> | PropsWithNone<T>;

export function PickerInput<T>(props: Props<T>) {
  const {
    options,
    getOptionKey,
    getOptionLabel = String,
    value,
    onChange,
    allowNone,
    placeholder,
    isLoading,
    hasError,
    disabled,
    className,
  } = props;

  const [isPickerOpen, setPickerOpen] = useState(false);

  function handlePick(item: T) {
    if (item === value) {
      if (allowNone) {
        onChange?.(null);
      }
    } else {
      onChange?.(item);
    }
    setPickerOpen(false);
  }

  function getItemContent(option: T) {
    return (
      <div className={styles.item}>
        {getOptionLabel(option)}
        {option === value && <Icon name="check" />}
      </div>
    );
  }

  useEscapeListener(() => setPickerOpen(false));

  const { clickAwayProps } = useClickAwayListener(() => setPickerOpen(false));

  return (
    <div {...clickAwayProps} className={styles.container + " " + (className ?? "")}>
      <button
        type="button"
        disabled={isLoading || hasError || disabled}
        onClick={() => setPickerOpen(!isPickerOpen)}
        className={styles.mainButton}
      >
        {hasError ? (
          <span className={styles.error}>An error occurred</span>
        ) : isLoading ? (
          <span className={styles.loading}>Loading...</span>
        ) : (
          <>
            {value === null ? (
              !placeholder || typeof placeholder === "string" ? (
                <span className={styles.placeholder}>{placeholder ?? "None"}</span>
              ) : (
                placeholder
              )
            ) : (
              getOptionLabel(value)
            )}
            <Icon name="expand_more" className={styles.chevron + " " + (isPickerOpen ? styles.chevronOpen : "")} />
          </>
        )}
      </button>

      {isPickerOpen && (
        <Picker
          items={options}
          getItemKey={getOptionKey}
          getItemContent={getItemContent}
          onPick={handlePick}
          className={styles.picker}
        />
      )}
    </div>
  );
}

export default PickerInput;
