import * as LOCAL from "./files/local.json";
import * as JAMIE from "./files/jamie.json";
import * as STAGING from "./files/staging.json";
import * as PRODUCTION from "./files/production.json";

import { Environment, EEnvironment } from "./types";

const environmentName = process.env.REACT_APP_ENVIRONMENT;

let environment: Environment = {} as Environment;

switch (environmentName) {
  case EEnvironment.Local:
    environment = LOCAL as Environment;
    break;
  case EEnvironment.Jamie:
    environment = JAMIE as Environment;
    break;
  case EEnvironment.Staging:
    environment = STAGING as Environment;
    break;
  case EEnvironment.Production:
    environment = PRODUCTION as Environment;
    break;
}

export default environment;
