import React, { PropsWithChildren, useCallback, useMemo, useState } from "react";

import ApiService from "../../../../services/api/api.service";

import {
  AuthSessionContext,
  AuthSessionTokensModel,
  loadAuthSessionTokens,
  storeAuthSessionTokens,
} from "../../../../modules/auth";

export function AuthSessionProvider(props: PropsWithChildren) {
  const { children } = props;

  const [tokens, setTokens] = useState<AuthSessionTokensModel | null>(() => {
    const tokens = loadAuthSessionTokens();
    ApiService.authTokens = tokens;
    return tokens;
  });

  const changeTokens = useCallback((tokens: AuthSessionTokensModel | null) => {
    ApiService.authTokens = tokens;
    setTokens(tokens);
    storeAuthSessionTokens(tokens);
  }, []);

  const contextValue = useMemo(
    () => ({
      authSessionTokens: tokens,
      setAuthSessionTokens: changeTokens,
    }),
    [tokens, changeTokens]
  );

  return <AuthSessionContext.Provider value={contextValue}>{children}</AuthSessionContext.Provider>;
}

export default AuthSessionProvider;
