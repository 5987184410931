import { BarElement, CategoryScale, Chart as ChartJS, Filler, LinearScale, Tooltip } from "chart.js";
import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";

import { ResponsiveChartContainer } from "../../containers";

import { barChartDatasetOptions, commonChartOptions } from "../chart-options";
import { combineChartOptions } from "../helpers";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Filler);

export type BarChartOptions = {
  currency?: string;
};

export type BarChartDataPoint = {
  label?: string;
  value: number;
};

type Props = {
  bars: BarChartDataPoint[];
  options?: BarChartOptions;
};

export function BarChart(props: Props) {
  const { bars, options } = props;

  const resolvedOptions = useMemo(
    () =>
      combineChartOptions([
        commonChartOptions,
        {
          scales: {
            x: {
              ticks: {
                maxTicksLimit: 12,
              },
            },
            y: {
              beginAtZero: true,
              suggestedMax: 1,
              ticks: {
                autoSkip: true,
                format: options?.currency
                  ? {
                      style: "currency",
                      currency: options.currency,
                      notation: "compact",
                    }
                  : undefined,
                maxTicksLimit: 5,
                stepSize: 1,
              },
            },
          },
        },
      ]),
    [options]
  );

  const data = useMemo(
    () => ({
      labels: bars.map((bar) => bar.label).filter(Boolean),
      datasets: [
        {
          ...barChartDatasetOptions,
          data: bars.map((bar) => bar.value),
        },
      ],
    }),
    [bars]
  );

  return (
    <ResponsiveChartContainer>
      <Bar options={resolvedOptions} data={data} />
    </ResponsiveChartContainer>
  );
}

export default BarChart;
