import React from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { AuthSessionProvider } from "../components/modules/auth";

import AppRouter from "./app-router";

import "./app.scss";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30 * 1000, // 30 seconds
    },
  },
});

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthSessionProvider>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </AuthSessionProvider>
    </QueryClientProvider>
  );
}

export default App;
