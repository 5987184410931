// eg. "first name" to "First Name"
export function phraseToTitle(text: string): string {
  return text
    .split(/\s+/)
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

// eg. "first name" -> "First name"
export function phraseToSentence(text: string): string {
  if (text.length === 0) {
    return "";
  } else {
    return text[0].toUpperCase() + text.slice(1).toLowerCase();
  }
}

// eg. "first name" to "firstName"
export function phraseToCamelCase(text: string): string {
  return text
    .split(/\s+/)
    .map((word, index) =>
      index === 0 ? word.toLowerCase() : (word[0] ?? "").toUpperCase() + word.slice(1).toLowerCase()
    )
    .join("");
}

// eg. "firstName" -> "First name"
export function camelCaseToSentence(text: string): string {
  return phraseToSentence(text.replace(/([^A-Z])([A-Z])/g, "$1 $2"));
}

// eg. "first-name" -> "First Name"
export function kebabCaseToTitle(text: string): string {
  return phraseToTitle(text.replace(/-/g, " "));
}

// eg. "first-name" -> "First name"
export function kebabCaseToSentence(text: string): string {
  return phraseToSentence(text.replace(/-/g, " "));
}

// eg. "first" -> "First"
export const capitalize = phraseToSentence;
