export class PartnerInvitationState {
  status: "pending" | "accepted";

  static fromRequest(params: Partial<PartnerInvitationState>) {
    const model = new PartnerInvitationState();
    model.initProperties(params);
    return model;
  }

  protected initProperties(params: Partial<PartnerInvitationState>) {
    Object.assign(this, params);
  }
}

export default PartnerInvitationState;
