import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";

import AuthService from "../auth.service";
import { AuthSessionContext } from "../contexts";

export function useSignIn() {
  const navigate = useNavigate();
  const { setAuthSessionTokens } = useContext(AuthSessionContext);

  return useCallback(async (email: string, password: string) => {
    const tokens = await AuthService.signIn(email, password);
    setAuthSessionTokens(tokens);
    navigate("/");
  }, []);
}
