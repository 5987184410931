import React from "react";
import styles from "./empty.state.module.scss";

type Props = {
  text?: string;
  children?: React.ReactNode;
};

export default function EmptyState(props: Props) {
  const { text, children } = props;

  return <div className={styles.container}>{children ?? text}</div>;
}
