import { useQuery } from "@tanstack/react-query";

import AccountService from "../account.service";
import { qkForAccountAutoPayoutSettings } from "../query-keys";

export function useAccountAutoPayoutSettingsQuery(accountId: string) {
  return useQuery({
    enabled: Boolean(accountId),
    queryKey: qkForAccountAutoPayoutSettings(accountId),
    queryFn: () => AccountService.fetchAutoPayoutSettings(accountId),
  });
}
