import React, { Dispatch, useReducer } from "react";

import { capitalize } from "../../../../modules/common";
import { WiseProfile, WiseService } from "../../../../modules/wise";

import {
  Button,
  ErrorText,
  ExternalLink,
  FormField,
  FormInstructions,
  FormSection,
  Input,
  MutedText,
  PickerInput,
  Stack,
  TextButton,
  Toggle,
} from "../../../themed";

import {
  AutoPayoutSettingsInputAction as InputAction,
  AutoPayoutSettingsInputState as InputState,
  createAutoPayoutSettingsInputState,
  updateAutoPayoutSettingsInputState,
} from "./auto-payout-settings-input-state";

import styles from "./styles.module.scss";

type CreateInputStateOptions = Parameters<typeof createAutoPayoutSettingsInputState>[0];
type UseInputOptions = CreateInputStateOptions;

export function useAutoPayoutSettingsInput(options?: UseInputOptions) {
  return useReducer(updateAutoPayoutSettingsInputState, options, createAutoPayoutSettingsInputState);
}

type WiseProfileOptionProps = {
  profile: WiseProfile;
};

function WiseProfileOption(props: WiseProfileOptionProps) {
  const { profile } = props;

  return (
    <div>
      <div className={styles.profileName}>{profile.fullName ? profile.fullName : <em>Unknown</em>}</div>
      <MutedText>{capitalize(profile.type)} profile</MutedText>
    </div>
  );
}

type Props = {
  state: InputState;
  dispatch: Dispatch<InputAction>;
};

export function AutoPayoutSettingsInput(props: Props) {
  const { state, dispatch } = props;

  function fetchProfiles() {
    // TODO: we should handle the case where this component unmounts while fetching
    if (!state.fetchingProfiles) {
      dispatch({ fetchingProfiles: true });
      WiseService.fetchWiseProfiles(state.wiseApiKey ?? "").then(
        (profiles) => dispatch({ wiseProfilesAvailable: profiles }),
        () => dispatch({ errorFetchingProfiles: true })
      );
    }
  }

  return (
    <Stack>
      <Toggle value={state.enabled} onChange={(enabled) => dispatch({ enabled })} />

      {state.enabled && (
        <FormSection>
          {!state.wiseProfilesAvailable ? (
            <FormField label="Wise API Token">
              <FormInstructions>
                Follow the instructions{" "}
                <ExternalLink href="https://docs.wise.com/api-docs/features/authentication-access/personal-tokens">
                  here
                </ExternalLink>{" "}
                to get your API token.
              </FormInstructions>
              <Stack horizontal>
                <Input
                  value={state.wiseApiKey ?? ""}
                  onChange={(event) => dispatch({ wiseApiKey: event.target.value })}
                  onEnter={fetchProfiles}
                  className={styles.apiKeyInput}
                />
                <Button
                  color="secondary"
                  size="small"
                  mode="outlined"
                  onClick={fetchProfiles}
                  disabled={(state.wiseApiKey ?? "").length === 0}
                >
                  Load
                </Button>
              </Stack>
              {state.errorFetchingProfiles && <ErrorText>There was an error loading your Wise profiles.</ErrorText>}
            </FormField>
          ) : (
            <FormField label="Wise Profile">
              <PickerInput<WiseProfile>
                options={state.wiseProfilesAvailable}
                getOptionKey={(profile) => String(profile.id)}
                getOptionLabel={(profile) => <WiseProfileOption profile={profile} />}
                value={state.wiseProfileSelected ?? null}
                onChange={(profile) => dispatch({ wiseProfileSelected: profile })}
                isLoading={state.fetchingProfiles}
                hasError={state.errorFetchingProfiles}
              />
              <div>
                <TextButton onClick={() => dispatch({ wiseApiKey: null })}>Change API Token</TextButton>
              </div>
            </FormField>
          )}
        </FormSection>
      )}
    </Stack>
  );
}

export default AutoPayoutSettingsInput;
