import { useMutation, useQueryClient } from "@tanstack/react-query";

import { PartnerPayoutSettings } from "../models";
import PartnersService from "../partners.service";
import { qkForPartnerPayoutSettings } from "../query-keys";

export function useUpdatePartnerPayoutSettingsMutation(partnerId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (settings: PartnerPayoutSettings) => PartnersService.updatePartnerPayoutSettings(partnerId, settings),
    onSuccess(newSettings) {
      queryClient.setQueryData(qkForPartnerPayoutSettings(partnerId), newSettings);
    },
  });
}

export default useUpdatePartnerPayoutSettingsMutation;
