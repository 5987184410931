import React, { Dispatch, useReducer } from "react";

import { FormField } from "../../form-elements";
import { Stack } from "../../stack";
import Input from "../input";
import { PickerInput } from "../picker-input";

import {
  MailingAddressInputState as InputState,
  MailingAddressInputAction as InputAction,
  createMailingAddressInputState,
  updateMailingAddressInputState,
} from "./mailing-address-input-state";
import { usStates } from "./us-states";

type CreateInputStateOptions = Parameters<typeof createMailingAddressInputState>[0];
type UseInputOptions = CreateInputStateOptions;

export function useMailingAddressInput(options?: UseInputOptions) {
  return useReducer(updateMailingAddressInputState, options, createMailingAddressInputState);
}

type Props = {
  state: InputState;
  dispatch: Dispatch<InputAction>;
};

export function MailingAddressInput(props: Props) {
  const { state, dispatch } = props;

  return (
    <Stack>
      <FormField label="Address">
        <Input value={state.addressLine} onChange={(event) => dispatch({ addressLine: event.target.value })} />
      </FormField>

      <Stack horizontal fill>
        <FormField label="City">
          <Input value={state.city} onChange={(event) => dispatch({ city: event.target.value })} />
        </FormField>

        <FormField label="State">
          <PickerInput
            value={usStates.find((s) => s.code === state.state) ?? null}
            onChange={(s) => dispatch({ state: s.code })}
            options={usStates}
            getOptionKey={(s) => s.code}
            getOptionLabel={(s) => s.name}
            placeholder="Please select"
          />
        </FormField>
      </Stack>

      <Stack horizontal fill>
        <FormField label="Zip Code">
          <Input value={state.zipCode} onChange={(event) => dispatch({ zipCode: event.target.value })} />
        </FormField>

        <FormField label="Country">
          <Input value="United States" disabled />
        </FormField>
      </Stack>
    </Stack>
  );
}

export default MailingAddressInput;
