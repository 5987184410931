import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

import styles from "./index.module.css";
import DefaultLoader from "../../themed/loaders/default.loader";

export default function EmptyLayout() {
  return (
    <div className={styles.container}>
      <Suspense fallback={<DefaultLoader />}>
        <Outlet />
      </Suspense>
    </div>
  );
}
