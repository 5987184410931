import React from "react";

import styles from "./styles.module.scss";

type Props = {
  value: boolean;
  onChange?: (newValue: boolean) => void;
};

export function Checkbox(props: Props) {
  const { value, onChange } = props;

  return (
    <input
      type="checkbox"
      checked={value}
      onChange={(event) => onChange?.(event.target.checked)}
      readOnly={!Boolean(onChange)}
      className={styles.checkbox}
    />
  );
}

export default Checkbox;
