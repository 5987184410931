import React from "react";

import styles from "./styles.module.scss";

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export function ChartCardsContainer(props: Props) {
  const { className, children } = props;

  return <div className={styles.container + " " + (className ?? "")}>{children}</div>;
}

export default ChartCardsContainer;
