import { MailingAddress } from "../../../../modules/common";

type InputState = {
  country: "us";
  state: string; // 2 char code
  city: string;
  addressLine: string;
  zipCode: string;
};
type InputAction = Partial<InputState>;

export type { InputState as MailingAddressInputState, InputAction as MailingAddressInputAction };

type CreateInputStateOptions = {
  initialValue?: Partial<Omit<MailingAddress, "country">>;
};

export function createMailingAddressInputState(options?: CreateInputStateOptions): InputState {
  return {
    country: "us",
    state: options?.initialValue?.state?.toLowerCase() ?? "",
    city: options?.initialValue?.city?.trim() ?? "",
    addressLine: options?.initialValue?.addressLine?.trim() ?? "",
    zipCode: options?.initialValue?.zipCode?.trim() ?? "",
  };
}

export function updateMailingAddressInputState(state: InputState, action: InputAction): InputState {
  return { ...state, ...action };
}

export function selectMailingAddressInputValue(state: InputState): MailingAddress {
  const address = new MailingAddress();
  address.country = state.country;
  address.state = state.state;
  address.city = state.city.trim();
  address.addressLine = state.addressLine.trim();
  address.zipCode = state.zipCode.trim();
  return address;
}

export function selectMailingAddressInputIsValid(state: InputState): boolean {
  const address = selectMailingAddressInputValue(state);
  return (
    address.state.length === 2 &&
    address.city.length > 0 &&
    address.addressLine.length > 0 &&
    address.zipCode.length >= 5
  );
}
