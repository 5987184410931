// This component deserves some explanation, as the logic can be a bit hard to follow.
//
// I've just refactored the web app so that if a user has multiple roles (such as administrator, account owner,
// partner, etc.) then they can select their current role using a dropdown. Most screens will expect there to be a
// "current role" selected so that they know, for example, which leads they should be showing to the user. (The
// exception would be something like the user settings screen; the user should be able to see that regardless of any
// currently selected role.)
//
// Ideally, we want to integrate the currently selected role with the browser history. This means that if the user
// changes their role using the dropdown, and then presses the "back" button, their current role should be reverted.
// The reason we'd want this behavior is because, as mentioned above, most screens expect a particular role to be
// selected. If, for example, you're viewing the leads on one account, then you switch to a role on another account to
// view those leads, and then press "back", you're likely expecting to go back to the list of leads on the first
// account; this is only possible if the current role is integrated with the browser history.
//
// As a result, the component below favors the user role provided in the location state, and tries to keep the state up
// to date. Unfortunately, there are some edge cases to consider:
//
//   - What if the location state doesn't have a user role (either because the user just signed in, or because they
//     clicked on a link provided by somebody else, etc.)?
//   - What if the user no longer has the role indicated by the location state?
//
// To handle these cases, we basically check for a "valid" (meaning the user still has the role) location role first,
// then we fallback to a cached role (whatever was previously selected), and if all else fails (perhaps because the
// user just signed in) we will automatically pick a "preferred" role (ie. we guess).

import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  EUserRoleType,
  UserRole,
  UserRoleContext,
  areUserRolesEqual,
  useCurrentUserQuery,
} from "../../../../modules/users";

function selectPreferredRole(roles: UserRole[]): UserRole {
  const ownerRole = roles.find((role) => role.type === EUserRoleType.AccountOwner);
  if (ownerRole) {
    return ownerRole;
  }

  const adminRole = roles.find((role) => role.type === EUserRoleType.Admin);
  if (adminRole) {
    return adminRole;
  }

  if (roles.length > 0) {
    return roles[0];
  } else {
    throw new Error("no roles given");
  }
}

export function UserRoleProvider(props: PropsWithChildren) {
  const { children } = props;

  const navigate = useNavigate();

  const location = useLocation();
  const locationRole = (location.state?.userRole as UserRole | undefined) ?? null;

  const { data: user } = useCurrentUserQuery();

  const [cachedRole, setCachedRole] = useState<UserRole | null>(null);

  const currentRole = useMemo(() => {
    if (user) {
      if (locationRole && user.hasRole(locationRole)) {
        return locationRole;
      }
      if (cachedRole && user.hasRole(cachedRole)) {
        return cachedRole;
      }
      if (user.roles.length > 0) {
        return selectPreferredRole(user.roles);
      }
    }
    return null;
  }, [locationRole, user, cachedRole]);

  // Add the current role to the browser history so that forward and back buttons work as expected.
  useEffect(() => {
    if (currentRole && (!locationRole || !areUserRolesEqual(currentRole, locationRole))) {
      navigate(window.location, { state: { userRole: currentRole }, replace: true });
    }
  }, [currentRole, locationRole]);

  // Cache the current role so that if the user clicks a link and the user role isn't included as state, we don't
  // accidentally select a different role from before.
  useEffect(() => {
    if (currentRole && (!cachedRole || !areUserRolesEqual(currentRole, cachedRole))) {
      setCachedRole(currentRole);
    }
  }, [currentRole, locationRole]);

  const contextValue = useMemo(() => {
    return {
      userRole: currentRole,
      setUserRole: (userRole: UserRole | null) => navigate(window.location, { state: { userRole } }),
    };
  }, [currentRole]);

  return <UserRoleContext.Provider value={contextValue}>{children}</UserRoleContext.Provider>;
}

export default UserRoleProvider;
