import React from "react";
import { Outlet } from "react-router-dom";

import { useCurrentAccountQuery } from "../../../../modules/account";

import { DefaultLoader, EmailAddressLink, FetchError } from "../../../themed";

import styles from "./styles.module.scss";

export function DisabledAccountGate() {
  const { data: account, isError, isSuccess } = useCurrentAccountQuery();

  if (isSuccess) {
    if (!account.isDisabled()) {
      return <Outlet />;
    } else {
      return (
        <div className={styles.disabledAccountContainer}>
          <h3>This account is disabled.</h3>
          <p>
            To enable this account, please contact us at <EmailAddressLink emailAddress="contact@leadpath.ai" />.
          </p>
        </div>
      );
    }
  } else if (isError) {
    return <FetchError />;
  } else {
    return <DefaultLoader center inline />;
  }
}

export default DisabledAccountGate;
