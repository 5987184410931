import React from "react";

import styles from "./styles.module.scss";

type Props = {
  label?: string;
  children: React.ReactNode;
};

export function SidebarSection(props: Props) {
  const { label, children } = props;

  return (
    <div className={styles.section}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.items}>{children}</div>
    </div>
  );
}

export default SidebarSection;
