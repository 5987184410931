import React, { ComponentProps, ReactNode } from "react";

import { Checkbox } from "../checkbox";

import styles from "./styles.module.scss";

type Props = ComponentProps<typeof Checkbox> & {
  children?: ReactNode;
};

export function LabelledCheckbox(props: Props) {
  const { children, ...rest } = props;

  return (
    <label className={styles.container}>
      <Checkbox {...rest} />
      {children}
    </label>
  );
}

export default LabelledCheckbox;
