import ApiService from "../../services/api/api.service";

import { HistoricalStatsPeriod } from "./enums";
import { AccountGeneralStatsModel, HistoricalStats } from "./models";
import { AccountHistoricalStats, LeadHistoricalStats, PartnerHistoricalStats } from "./types";

export class StatsService {
  private static api = new ApiService();

  static async fetchAccountStats(accountId: string): Promise<AccountGeneralStatsModel> {
    return await this.api
      .authenticated()
      .get(`/accounts/${accountId}/stats`)
      .then((response) => AccountGeneralStatsModel.fromRequest(response.data));
  }

  static async fetchAccountHistoricalStats(
    accountId: string,
    period: HistoricalStatsPeriod
  ): Promise<AccountHistoricalStats> {
    return await this.api
      .authenticated()
      .get(`/accounts/${accountId}/historical-stats/${period}`)
      .then((response) =>
        HistoricalStats.fromRequest(response.data, {
          numberOfPartners: 0,
          numberOfLeads: 0,
          numberOfSales: 0,
          salesRevenue: 0,
          salesProfit: 0,
        })
      );
  }

  static async fetchPartnerHistoricalStats(
    partnerId: string,
    period: HistoricalStatsPeriod
  ): Promise<PartnerHistoricalStats> {
    return await this.api
      .authenticated()
      .get(`/partners/${partnerId}/historical-stats/${period}`)
      .then((response) =>
        HistoricalStats.fromRequest(response.data, {
          numberOfPartners: 0,
          numberOfLeads: 0,
          numberOfSales: 0,
          salesRevenue: 0,
          salesProfit: 0,
        })
      );
  }

  static async fetchLeadHistoricalStats(leadId: string, period: HistoricalStatsPeriod): Promise<LeadHistoricalStats> {
    return await this.api
      .authenticated()
      .get(`/leads/${leadId}/historical-stats/${period}`)
      .then((response) =>
        HistoricalStats.fromRequest(response.data, {
          numberOfSales: 0,
          salesRevenue: 0,
          salesProfit: 0,
        })
      );
  }
}

export default StatsService;
