import { OptionsEnum } from "./enums";

export const ConfigNames = {
  [OptionsEnum.FirstName]: "First Name",
  [OptionsEnum.LastName]: "Last Name",
  [OptionsEnum.Email]: "Email",
  [OptionsEnum.Phone]: "Phone",
  [OptionsEnum.FieldsContainer]: "Fields Container",
  [OptionsEnum.SubmitButton]: "Submit Button",
};
