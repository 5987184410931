import React from "react";

import styles from "./styles.module.scss";

type Props = {
  message?: string;
};

export function FetchError(props: Props) {
  const { message = "Failed to load" } = props;

  return <div className={styles.container}>{message}</div>;
}

export default FetchError;
