import React, { PropsWithChildren } from "react";

import styles from "./styles.module.scss";

type Props = PropsWithChildren<{
  className?: string;
}>;

export function InlineInputsContainer(props: Props) {
  const { className, children } = props;

  return <div className={`${styles.container} ${className ?? ""}`}>{children}</div>;
}

export default InlineInputsContainer;
