import React, { PropsWithChildren } from "react";

import styles from "./styles.module.scss";

type Props = PropsWithChildren<{
  gap?: "sm" | "md" | "lg";
  horizontal?: boolean;
  fill?: boolean;
  className?: string;
}>;

export function Stack(props: Props) {
  const { gap = "md", horizontal, fill, className: ogClassName, children } = props;

  const className = [
    styles.container,
    gap === "sm" ? styles.gapSm : undefined,
    gap === "md" ? styles.gapMd : undefined,
    gap === "lg" ? styles.gapLg : undefined,
    horizontal ? styles.horizontal : undefined,
    fill ? styles.fill : undefined,
    ogClassName,
  ]
    .filter(Boolean)
    .join(" ");

  return <div className={className}>{children}</div>;
}
