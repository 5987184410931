import { ScriptableContext } from "chart.js";

export function createChartGradientBackground(context: ScriptableContext<"line" | "bar">) {
  const { ctx, chartArea } = context.chart;

  // The types aren't correct; `chartArea` is undefined upon page load.
  if (chartArea) {
    const gradient = ctx.createLinearGradient(0, 0, 0, chartArea.height);

    gradient.addColorStop(0, "rgba(75,207,204, 0.6)");
    gradient.addColorStop(0.8, "rgba(75,207,204, 0.1)");
    gradient.addColorStop(1, "rgba(75,207,204, 0.1)");

    return gradient;
  } else {
    return "transparent";
  }
}

export default createChartGradientBackground;
