import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { EUserRoleType, useCurrentUserRole } from "../../users";

import { PartnerModel } from "../models";
import PartnersService from "../partners.service";
import { qkForAccountPartners, qkForSubPartners } from "../query-keys";

export function useMyPartnersQuery(options?: UseQueryOptions<PartnerModel[]>) {
  const role = useCurrentUserRole();

  return useQuery<PartnerModel[]>({
    ...options,
    enabled: Boolean(role) && (options?.enabled ?? true),
    queryKey:
      role?.type === EUserRoleType.AccountOwner
        ? qkForAccountPartners(role.accountId)
        : role?.type === EUserRoleType.Partner
        ? qkForSubPartners(role.partnerId)
        : undefined,
    queryFn:
      role?.type === EUserRoleType.AccountOwner
        ? () => PartnersService.fetchAccountPartners(role.accountId)
        : role?.type === EUserRoleType.Partner
        ? () => PartnersService.fetchSubPartners(role.partnerId)
        : undefined,
  });
}
