import ApiService from "../../services/api/api.service";

import { ChangePasswordDto, ResetPasswordDto } from "./dtos";
import { AuthSessionTokensModel } from "./models";

export class AuthService {
  private static api = new ApiService();

  static async signIn(email: string, password: string): Promise<AuthSessionTokensModel> {
    return await this.api
      .post(`/auth/sign-in`, { email, password })
      .then((response) => AuthSessionTokensModel.fromRequest(response.data));
  }

  static async resetPassword(dto: ResetPasswordDto) {
    await this.api.post("/auth/reset-password", dto);
  }

  static async changePassword(dto: ChangePasswordDto): Promise<AuthSessionTokensModel> {
    const { token, newPassword } = dto;

    return await this.api
      .withAuthHeader(token)
      .post("/auth/change-password", { password: newPassword })
      .then((response) => AuthSessionTokensModel.fromRequest(response.data));
  }
}

export default AuthService;
