export const qkForAllRewardData = () => ["reward-data"];

export const qkForRewards = (accountId: string, status?: string) => {
  const baseKey = ["reward-data", "rewards", "for-account", accountId];
  return status ? [...baseKey, "with-status", status] : baseKey;
};

export const qkForUnpaidPayouts = (accountId: string) => ["reward-data", "payouts", "for-account", accountId, "unpaid"];

export const qkForPayout = (payoutId: string) => ["reward-data", "payouts", payoutId];

export const qkForPayoutPlan = (rewardIds: string[]) => ["reward-data", "payout-plans", rewardIds];
