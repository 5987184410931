import { CategoryScale, Chart as ChartJS, Filler, LinearScale, LineElement, PointElement, Tooltip } from "chart.js";
import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";

import { ResponsiveChartContainer } from "../../containers";

import { commonChartOptions, lineChartDatasetOptions } from "../chart-options";
import { combineChartOptions } from "../helpers";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Filler);

export type LineChartOptions = {
  currency?: string;
};

export type LineChartDataPoint = {
  label?: string;
  value: number;
};

type Props = {
  points: LineChartDataPoint[];
  options?: LineChartOptions;
};

export function LineChart(props: Props) {
  const { points, options } = props;

  const resolvedOptions = useMemo(
    () =>
      combineChartOptions([
        commonChartOptions,
        {
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                maxTicksLimit: 7,
                format: options?.currency
                  ? {
                      style: "currency",
                      currency: options.currency,
                      notation: "compact",
                    }
                  : undefined,
              },
            },
          },
        },
      ]),
    [options]
  );

  const data = useMemo(
    () => ({
      labels: points.map((point) => point.label).filter(Boolean),
      datasets: [
        {
          ...lineChartDatasetOptions,
          data: points.map((point) => point.value),
        },
      ],
    }),
    [points]
  );

  return (
    <ResponsiveChartContainer>
      <Line options={resolvedOptions} data={data} />
    </ResponsiveChartContainer>
  );
}

export default LineChart;
