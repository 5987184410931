import { useMutation, useQueryClient } from "@tanstack/react-query";

import { qkForAccount, useCurrentAccountId } from "../../account";

import { CreatePartnerDto } from "../dto";
import PartnersService from "../partners.service";
import { qkForAccountPartners, qkForSubPartners } from "../query-keys";

export function useCreatePartnerMutation() {
  const queryClient = useQueryClient();
  const accountId = useCurrentAccountId() ?? "";

  return useMutation({
    async mutationFn(dto: CreatePartnerDto) {
      if (dto.parentPartnerId) {
        return await PartnersService.createSubPartner(dto.parentPartnerId, dto);
      } else {
        return await PartnersService.createAccountPartner(accountId, dto);
      }
    },
    onSuccess(partner) {
      queryClient.invalidateQueries({ queryKey: qkForAccount(accountId) });
      queryClient.invalidateQueries({ queryKey: qkForAccountPartners(accountId) });

      if (partner.parentPartnerId) {
        queryClient.invalidateQueries({ queryKey: qkForSubPartners(partner.parentPartnerId) });
      }
    },
  });
}

export default useCreatePartnerMutation;
