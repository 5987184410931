import { useMutation, useQueryClient } from "@tanstack/react-query";

import { qkForAllPartnerData } from "../../partners/query-keys";
import { qkForAllRewardData } from "../../rewards/query-keys";

import AccountService from "../account.service";
import { UpdateAccountDto } from "../dto";
import { qkForAccount, qkForAccountAutoPayoutSettings, qkForAllAccounts } from "../query-keys";
import { useCurrentAccountId } from "./use-current-account-id";

export function useUpdateCurrentAccountMutation() {
  const queryClient = useQueryClient();
  const accountId = useCurrentAccountId() ?? "";

  return useMutation({
    mutationFn: (dto: UpdateAccountDto) => AccountService.updateAccount(accountId, dto),
    onSuccess(account) {
      queryClient.invalidateQueries({ queryKey: qkForAllAccounts() });
      queryClient.setQueryData(qkForAccount(account.id), account);

      // If the payout delay was changed, then the status of rewards may have changed.
      queryClient.invalidateQueries({ queryKey: qkForAllRewardData() });

      // The account's auto-payout settings may have also changed.
      queryClient.invalidateQueries({ queryKey: qkForAccountAutoPayoutSettings(account.id) });

      // If the account's auto-payouts ettings changed, then partner payout settings will also be different.
      //
      // Note the use of `resetQueries()`. If we used `invalidateQueries()`, it keeps the old settings in cache and
      // simply refetches them, but the partner settings form isn't reactive to query updates; it accepts an initial
      // value, and then allows the user to update that as they please.
      //
      // Honestly, we should consider whether we want to cache that data at all.
      //
      queryClient.resetQueries({ queryKey: qkForAllPartnerData() });
    },
  });
}

export default useUpdateCurrentAccountMutation;
