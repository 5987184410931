import React from "react";
import { useLocation, matchPath } from "react-router-dom";

import { useMyPaymentProvidersQuery } from "../../../../modules/integrations/payment/hooks";
import { useMyPartnersQuery } from "../../../../modules/partners/hooks";
import { EUserRoleType, useCurrentUserRole } from "../../../../modules/users";

import SimpleNotification from "../../../themed/notifications/simple.notification";

import styles from "./app.top.notifications.module.scss";

const paymentProvidersUrl = "/integrations/payment-providers";
const createPartnerUrl = "/partners/new";

export default function AppTopNotifications() {
  const location = useLocation();
  const isOnPaymentProvidersScreen = matchPath(location.pathname, paymentProvidersUrl) !== null;
  const isOnCreatePartnerScreen = matchPath(location.pathname, createPartnerUrl) !== null;

  const currentRole = useCurrentUserRole();
  const isOwner = currentRole?.type === EUserRoleType.AccountOwner;

  const { data: partners, isSuccess: hasFetchedPartners } = useMyPartnersQuery();
  const hasPartners = hasFetchedPartners && partners.length > 0;

  const { data: paymentProviders, isSuccess: hasFetchedPaymentProviders } = useMyPaymentProvidersQuery({
    enabled: isOwner,
  });
  const hasPaymentProviders = hasFetchedPaymentProviders && paymentProviders.length > 0;

  const showPaymentNotification =
    isOwner && hasFetchedPaymentProviders && !hasPaymentProviders && !isOnPaymentProvidersScreen;
  const showCreatePartnerNotification =
    !showPaymentNotification && hasFetchedPartners && !hasPartners && !isOnCreatePartnerScreen;

  return (
    <div className={styles.container}>
      {showPaymentNotification && (
        <SimpleNotification
          link={paymentProvidersUrl}
          linkText="Go to payment providers"
          text="Set up your payment integration to start tracking sales"
        />
      )}

      {showCreatePartnerNotification && (
        <SimpleNotification
          link={createPartnerUrl}
          linkText="Add partner"
          text="Add your first partner and start tracking sales"
        />
      )}
    </div>
  );
}
