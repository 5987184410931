export class CommissionSettings {
  layerRates: number[]; // Number(12.5) == Commission rate of 12.5%

  get isSolo(): boolean {
    return this.layerRates.length === 1;
  }
  get isLayered(): boolean {
    return this.layerRates.length > 1;
  }
  get soloRate(): number | undefined {
    return this.layerRates[0];
  }

  get isZero(): boolean {
    return this.layerRates.every((rate) => rate === 0);
  }

  sameAs(other: CommissionSettings): boolean {
    for (let i = 0; i < Math.max(this.layerRates.length, other.layerRates.length); ++i) {
      if (this.layerRates[i] ?? 0 !== other.layerRates[i] ?? 0) {
        return false;
      }
    }
    return true;
  }

  static fromSoloRate(rate: number) {
    return CommissionSettings.fromLayerRates([rate]);
  }

  static fromLayerRates(layerRates: number[]) {
    const model = new CommissionSettings();
    model.layerRates = layerRates;
    return model;
  }

  static fromRequest(params: any) {
    const model = new CommissionSettings();
    model.initProperties(params);
    return model;
  }

  protected initProperties(params: any) {
    Object.assign(this, params);
  }
}

export default CommissionSettings;
