import { useQuery } from "@tanstack/react-query";

import AccountService from "../account.service";
import { qkForAccountOwner } from "../query-keys";

export function useAccountOwnerQuery(accountId: string) {
  return useQuery({
    queryKey: qkForAccountOwner(accountId),
    queryFn: () => AccountService.fetchAccountOwner(accountId),
  });
}
