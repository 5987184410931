import React, { PropsWithChildren } from "react";

import { ExternalLink } from "../external-link";

type Props = PropsWithChildren<{
  emailAddress: string;
  className?: string;
}>;

export function EmailAddressLink(props: Props) {
  const { emailAddress, className, children } = props;

  return (
    <ExternalLink href={`mailto:${emailAddress}`} className={className}>
      {children ?? emailAddress}
    </ExternalLink>
  );
}

export default EmailAddressLink;
