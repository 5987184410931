import React from "react";

import { FullDate } from "../full-date";

import styles from "./styles.module.scss";

type Props = {
  createdAt?: Date;
  updatedAt?: Date;
};

export function TimestampFooter(props: Props) {
  const { createdAt, updatedAt } = props;

  if (!createdAt && !updatedAt) {
    return null;
  }

  const areDatesEqual = createdAt?.getTime() === updatedAt?.getTime();

  return (
    <div className={styles.container}>
      {createdAt && (
        <div>
          Created on <FullDate date={createdAt} />.
        </div>
      )}
      {updatedAt && !areDatesEqual && (
        <div>
          Updated on <FullDate date={updatedAt} />.
        </div>
      )}
    </div>
  );
}

export default TimestampFooter;
