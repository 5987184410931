import { AutoPayoutSettings } from "../../../../modules/account";
import { WiseProfile } from "../../../../modules/wise";

type InputState = {
  enabled: boolean;
  wiseApiKey?: string;
  wiseProfileSelected?: WiseProfile;
  wiseProfilesAvailable?: WiseProfile[];
  fetchingProfiles: boolean;
  errorFetchingProfiles: boolean;
};
type InputAction =
  | { enabled: boolean }
  | { wiseApiKey: string | null }
  | { fetchingProfiles: boolean }
  | { errorFetchingProfiles: boolean }
  | { wiseProfilesAvailable: WiseProfile[] }
  | { wiseProfileSelected: WiseProfile };

export type { InputState as AutoPayoutSettingsInputState, InputAction as AutoPayoutSettingsInputAction };

type CreateInputStateOptions = {
  initialValue?: AutoPayoutSettings;
};

export function createAutoPayoutSettingsInputState(options?: CreateInputStateOptions): InputState {
  const initialValue = options?.initialValue;

  if (initialValue && initialValue.enabled) {
    return {
      enabled: true,
      wiseProfileSelected: initialValue.wiseProfile,
      wiseProfilesAvailable: initialValue.wiseProfilesAvailable,
      fetchingProfiles: false,
      errorFetchingProfiles: !Array.isArray(initialValue.wiseProfilesAvailable),
    };
  } else {
    return {
      enabled: false,
      fetchingProfiles: false,
      errorFetchingProfiles: false,
    };
  }
}

export function updateAutoPayoutSettingsInputState(state: InputState, action: InputAction): InputState {
  if ("enabled" in action) {
    state = { ...state, enabled: action.enabled };
  }
  if ("wiseApiKey" in action) {
    if (action.wiseApiKey === null) {
      state = {
        ...state,
        wiseApiKey: undefined,
        wiseProfileSelected: undefined,
        wiseProfilesAvailable: undefined,
        fetchingProfiles: false,
        errorFetchingProfiles: false,
      };
    } else {
      state = { ...state, wiseApiKey: action.wiseApiKey };
    }
  }
  if ("fetchingProfiles" in action) {
    state = { ...state, fetchingProfiles: action.fetchingProfiles };
    if (action.fetchingProfiles) {
      state = { ...state, wiseProfileSelected: undefined, wiseProfilesAvailable: undefined };
    }
  }
  if ("errorFetchingProfiles" in action) {
    state = { ...state, errorFetchingProfiles: action.errorFetchingProfiles };
    if (action.errorFetchingProfiles) {
      state = { ...state, wiseProfileSelected: undefined, wiseProfilesAvailable: undefined, fetchingProfiles: false };
    }
  }
  if ("wiseProfilesAvailable" in action) {
    state = {
      ...state,
      wiseProfilesAvailable: action.wiseProfilesAvailable,
      fetchingProfiles: false,
      errorFetchingProfiles: false,
    };

    // If the selected profile is no longer available, select the first one that is (or select none).
    const selectionValid = state.wiseProfilesAvailable?.some((p) => p.id === state.wiseProfileSelected?.id);
    if (!selectionValid) {
      const firstProfile = state.wiseProfilesAvailable?.[0];
      if (firstProfile) {
        state = updateAutoPayoutSettingsInputState(state, { wiseProfileSelected: firstProfile });
      } else {
        state = { ...state, wiseProfileSelected: undefined };
      }
    }
  }
  if ("wiseProfileSelected" in action) {
    state = { ...state, wiseProfileSelected: action.wiseProfileSelected };
  }

  return state;
}

export function selectAutoPayoutSettingsInputIsValid(state: InputState): boolean {
  return !state.enabled || Boolean(state.wiseProfileSelected);
}

export function selectAutoPayoutSettingsInputEnabled(state: InputState): boolean {
  return state.enabled;
}

export function selectAutoPayoutSettingsInputWiseApiKey(state: InputState): string | undefined {
  return state.wiseApiKey;
}

export function selectAutoPayoutSettingsInputWiseProfileId(state: InputState): number | undefined {
  return state.wiseProfileSelected?.id;
}
