import { useContext } from "react";

import { AuthSessionContext } from "../contexts";

export function useIsAuthenticated() {
  const { authSessionTokens } = useContext(AuthSessionContext);

  return Boolean(authSessionTokens);
}

export default useIsAuthenticated;
