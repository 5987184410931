import { phraseToCamelCase } from "./strings";

export type Slugger = {
  toSlug: (s: string) => string;
};

export function createSlugger() {
  const pairs: string[] = [];

  return {
    toSlug(s: string): string {
      let slug = phraseToCamelCase(s);

      if (pairs.includes(slug)) {
        const og = slug;
        let counter = 1;
        while (pairs.includes(slug)) {
          slug = `${og}_${counter++}`;
        }
      }

      pairs.push(slug);
      return slug;
    },
  };
}
