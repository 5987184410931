import ApiService from "../../services/api/api.service";

import { WiseProfile } from "./models";

export class WiseService {
  private static api = new ApiService();

  static fetchWiseProfiles(wiseApiKey: string): Promise<WiseProfile[]> {
    return WiseService.api
      .withAuthHeader(`wise:${wiseApiKey}`)
      .get("/wise/profiles")
      .then((response) => response.data.map(WiseProfile.fromRequest));
  }
}
