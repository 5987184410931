import React, { PropsWithChildren } from "react";

import { RelativeDateRange } from "../date-range";

import styles from "./styles.module.scss";

type Props = PropsWithChildren & {
  range: RelativeDateRange;
  setRange: (newRange: RelativeDateRange) => void;
};

export function RelativeRangeButton(props: Props) {
  const { range, setRange, children } = props;

  return (
    <button type="button" onClick={() => setRange(range)} className={styles.button}>
      {children}
    </button>
  );
}

export default RelativeDateRange;
