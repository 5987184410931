import React from "react";

import styles from "./icon.module.css";

export type IconProps = {
  name: string;
  title?: string;
  className?: string;
};

export function Icon({ name, className, ...rest }: IconProps) {
  return (
    <span className={`${styles.icon} ${className}`} {...rest}>
      {name}
    </span>
  );
}

export default Icon;
