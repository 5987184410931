import React from "react";

import { Root } from "@radix-ui/react-accordion";

import styles from "./styles.module.scss";

export const AccordionRoot = React.forwardRef<
  React.ElementRef<typeof Root>,
  React.ComponentPropsWithoutRef<typeof Root> & { className?: string } // HACK
>((props, forwardedRef) => (
  <Root {...props} ref={forwardedRef} className={styles.root + " " + (props.className ?? "")} />
));

AccordionRoot.displayName = "AccordionRoot";

export default AccordionRoot;
