import { createContext } from "react";

import { AuthSessionTokensModel } from "../models";

export type AuthSessionContextType = {
  authSessionTokens: AuthSessionTokensModel | null;
  setAuthSessionTokens: (sessionAuth: AuthSessionTokensModel | null) => void;
};

export const AuthSessionContext = createContext<AuthSessionContextType>({
  authSessionTokens: null,
  setAuthSessionTokens() {
    // Do nothing
  },
});

export default AuthSessionContext;
