import React from "react";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

type Props = {
  to: string;
  className?: string;
  children?: React.ReactNode;
};

export function InternalLink(props: Props) {
  const { to, className, children } = props;

  return (
    <Link to={to} className={styles.link + " " + (className ?? "")}>
      {children}
    </Link>
  );
}

export default InternalLink;
