import React, { ComponentProps } from "react";

import InlineRadioInput from "../inline-radio-input";

import styles from "./styles.module.scss";

type Props<T> = ComponentProps<typeof InlineRadioInput<T>>;

export function AltInlineRadioInput<T>(props: Props<T>) {
  return (
    <InlineRadioInput
      {...props}
      className={styles.container + " " + (props.className ?? "")}
      itemClassName={styles.item}
    />
  );
}

export default AltInlineRadioInput;
