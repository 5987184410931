import React, { PropsWithChildren } from "react";

import styles from "./styles.module.scss";

type Props = PropsWithChildren<{
  className?: string;
}>;

export function FormInstructions(props: Props) {
  const { className, children } = props;

  return <p className={`${styles.formInstructions} ${className ?? ""}`}>{children}</p>;
}

export default FormInstructions;
