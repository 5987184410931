import React from "react";

import appLogo from "./assets/app-logo.svg";
import appLogoMark from "./assets/app-logo-mark.svg";

import styles from "./styles.module.scss";

/** Preload logos to avoid flicker **/
new Image().src = appLogo;
new Image().src = appLogoMark;

export enum LogoType {
  Full = "full",
  LogoMark = "logo-mark",
}

type Props = {
  size?: number;
  type?: LogoType;
};

export default function Logo(props: Props) {
  const { size, type = LogoType.Full } = props;

  const logo = type === LogoType.Full ? appLogo : appLogoMark;

  return <img data-size={size} className={styles.logo} src={logo} alt="App logo" />;
}
