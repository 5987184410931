import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { useCurrentAccountId } from "../../../account/hooks";

import PaymentIntegrationModel from "../models/payment.integration.model";
import PaymentIntegrationService from "../payment.integration.service";
import { qkForAccountPaymentProviders } from "../query-keys";

export function useMyPaymentProvidersQuery(options?: UseQueryOptions<PaymentIntegrationModel[]>) {
  const accountId = useCurrentAccountId() ?? "";

  return useQuery<PaymentIntegrationModel[]>({
    ...options,
    queryKey: qkForAccountPaymentProviders(accountId),
    queryFn: () => PaymentIntegrationService.fetchPaymentProviders(accountId),
    enabled: Boolean(accountId) && options?.enabled !== false,
  });
}
