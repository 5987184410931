export class AuthSessionTokensModel {
  accessToken: string;
  refreshToken: string;

  static fromRequest(params: Partial<AuthSessionTokensModel>) {
    const model = new AuthSessionTokensModel();
    model.initProperties(params);
    return model;
  }

  protected initProperties(params: Partial<AuthSessionTokensModel>) {
    Object.assign(this, params);
  }
}

export default AuthSessionTokensModel;
