import React, { PropsWithChildren } from "react";

import styles from "./styles.module.scss";

type Props = PropsWithChildren<{
  className?: string;
}>;

export function Screen(props: Props) {
  const { className, children } = props;

  return <div className={`${styles.screen} ${className ?? ""}`}>{children}</div>;
}

export default Screen;
