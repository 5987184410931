import React from "react";

import { Header } from "@radix-ui/react-accordion";

import styles from "./styles.module.scss";

export const AccordionHeader = React.forwardRef<
  React.ElementRef<typeof Header>,
  React.ComponentPropsWithoutRef<typeof Header> & { className?: string } // HACK
>((props, forwardedRef) => (
  <Header {...props} ref={forwardedRef} className={styles.header + " " + (props.className ?? "")} />
));

AccordionHeader.displayName = "AccordionHeader";

export default AccordionHeader;
