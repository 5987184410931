import { WiseProfile } from "../../wise";

export class AutoPayoutSettings {
  enabled: boolean;
  wiseProfile?: WiseProfile;
  wiseProfilesAvailable?: WiseProfile[];

  static fromRequest(details: any) {
    const settings = new AutoPayoutSettings();
    settings.enabled = details.enabled ?? false;

    if (details.wiseProfile) {
      settings.wiseProfile = WiseProfile.fromRequest(details.wiseProfile);
    }
    if (details.wiseProfilesAvailable) {
      settings.wiseProfilesAvailable = details.wiseProfilesAvailable.map(WiseProfile.fromRequest);
    }

    return settings;
  }
}
