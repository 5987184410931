import moment from "moment";

import { HistoricalStatsPeriod } from "../enums";

const dateFormat = "YYYY-MM-DD";

function roundToStartOfPeriod(startDate: string, period: HistoricalStatsPeriod): string {
  return moment(startDate).startOf(period).format(dateFormat);
}

function roundToEndOfPeriod(endDate: string, period: HistoricalStatsPeriod): string {
  return moment(endDate).endOf(period).format(dateFormat);
}

function isWithinDateRange(dateToCheck: string, startDate: string, endDate: string): boolean {
  return moment(dateToCheck).isSameOrAfter(startDate) && moment(dateToCheck).isSameOrBefore(endDate);
}

type WithDate<T> = T & { date: string };

function fillMissingDeltas<Delta>(
  deltas: WithDate<Delta>[],
  startDate: string,
  endDate: string,
  period: HistoricalStatsPeriod,
  defaultDelta: Delta
): WithDate<Delta>[] {
  deltas = deltas.slice();

  function upsertDeltaForDate(date: string) {
    const deltaForDateExists = deltas.some((delta) => delta.date === date);
    if (!deltaForDateExists) {
      deltas.push({ ...defaultDelta, date });
    }
  }

  const roundedStartDate = roundToStartOfPeriod(startDate, period);
  const roundedEndDate = roundToEndOfPeriod(endDate, period);

  for (
    let date = roundedStartDate;
    moment(date).isSameOrBefore(roundedEndDate);
    date = moment(date).add(1, period).format(dateFormat)
  ) {
    upsertDeltaForDate(date);
  }

  return deltas.sort((a, b) => a.date.localeCompare(b.date));
}

function filterDeltas<Delta>(deltas: WithDate<Delta>[], startDate: string, endDate: string): WithDate<Delta>[] {
  return deltas.filter((delta) => isWithinDateRange(delta.date, startDate, endDate));
}

type ConstructorParams<Delta> = {
  startDate: string;
  endDate: string;
  period: HistoricalStatsPeriod;
  deltas: WithDate<Delta>[];
  defaultDelta?: Delta;
};

export class HistoricalStats<Delta> {
  startDate: string;
  endDate: string;
  period: HistoricalStatsPeriod;
  deltas: WithDate<Delta>[];
  private defaultDelta?: Delta;

  constructor(params: ConstructorParams<Delta>) {
    this.startDate = params.startDate;
    this.endDate = params.endDate;
    this.period = params.period;
    this.deltas = params.deltas.slice().sort((a, b) => a.date.localeCompare(b.date));
    this.defaultDelta = params.defaultDelta;
  }

  get filledDeltas() {
    if (this.defaultDelta) {
      return fillMissingDeltas(this.deltas, this.startDate, this.endDate, this.period, this.defaultDelta);
    } else {
      return this.deltas;
    }
  }

  // Note that unlike `filterByDateRange`, this method may return deltas for dates outside of the range that this stats
  // object has data for. In other words, if this stats object contains deltas for June through August, and you ask for
  // deltas from January to March, this method will return empty deltas for those months. `filterByDateRange` will
  // correctly round the dates, restricting them to the date range that we have data for.
  //
  filledDeltasByDateRange(startDate: string, endDate: string): WithDate<Delta>[] {
    const roundedStartDate = roundToStartOfPeriod(startDate, this.period);
    const roundedEndDate = roundToEndOfPeriod(endDate, this.period);

    const filteredDeltas = filterDeltas(this.deltas, roundedStartDate, roundedEndDate);

    if (this.defaultDelta) {
      return fillMissingDeltas(filteredDeltas, roundedStartDate, roundedEndDate, this.period, this.defaultDelta);
    } else {
      return filteredDeltas;
    }
  }

  filterByDateRange(startDate: string, endDate: string): HistoricalStats<Delta> {
    const roundedStartDate = roundToStartOfPeriod(startDate, this.period);
    const roundedEndDate = roundToEndOfPeriod(endDate, this.period);

    return new HistoricalStats({
      startDate: moment.max([moment(this.startDate), moment(roundedStartDate)]).format(dateFormat),
      endDate: moment.min([moment(this.endDate), moment(roundedEndDate)]).format(dateFormat),
      period: this.period,
      deltas: filterDeltas(this.deltas, roundedStartDate, roundedEndDate),
    });
  }

  static fromRequest<Delta>(
    input: any,
    defaultDelta?: ConstructorParams<Delta>["defaultDelta"]
  ): HistoricalStats<Delta> {
    return new HistoricalStats({
      startDate: moment(input.startDate).format(dateFormat),
      endDate: moment(input.endDate).format(dateFormat),
      period: input.period,
      deltas: input.deltas.map((deltaInput: any) => ({
        ...deltaInput,
        date: moment(deltaInput.date).format(dateFormat),
      })),
      defaultDelta,
    });
  }
}

export default HistoricalStats;
