import React, { PropsWithChildren, ReactNode } from "react";

import styles from "./styles.module.scss";

type Props = PropsWithChildren<{
  title?: ReactNode;
  subtitle?: ReactNode;
  className?: ReactNode;
}>;

export function ScreenTitle(props: Props) {
  const { title, subtitle, className, children } = props;

  return (
    <div className={`${styles.container} ${className ?? ""}`}>
      <h1 className={styles.title}>{title ?? children}</h1>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </div>
  );
}

export default ScreenTitle;
