import React from "react";

import { InlineRadioInput } from "../inline-radio-input";

type Props = {
  value: boolean;
  onChange?: (newValue: boolean) => void;
};

export function Toggle(props: Props) {
  const { value, onChange } = props;

  return (
    <InlineRadioInput
      value={value}
      onChange={onChange}
      options={[false, true]}
      getOptionLabel={(option) => (option ? "Enabled" : "Disabled")}
    />
  );
}

export default Toggle;
