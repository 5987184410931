import React from "react";

import styles from "./styles.module.scss";

type Props<T> = {
  items: T[];
  getItemKey?: (option: T) => string;
  getItemClass?: (option: T) => string;
  getItemContent?: (option: T) => React.ReactNode;
  onPick?: (option: T) => void;
  className?: string;
};

export function Picker<T>(props: Props<T>) {
  const { items, getItemKey, getItemClass, getItemContent = String, onPick, className } = props;

  return (
    <div className={styles.picker + " " + (className ?? "")}>
      {items.map((item, index) => {
        const key = getItemKey?.(item) ?? index;
        const className = styles.item + " " + (getItemClass?.(item) ?? "");
        const content = getItemContent(item);
        return (
          <button key={key} type="button" onClick={() => onPick?.(item)} className={className}>
            {content}
          </button>
        );
      })}
    </div>
  );
}

export default Picker;
