import { useQuery } from "@tanstack/react-query";

import AccountService from "../account.service";
import { qkForAccount } from "../query-keys";

export function useAccountQuery(accountId: string) {
  return useQuery({
    queryKey: qkForAccount(accountId),
    queryFn: () => AccountService.fetchAccount(accountId),
    enabled: Boolean(accountId),
  });
}

export default useAccountQuery;
