import ApiService from "../../services/api/api.service";
import { UpdateUserDto } from "./dtos";

import { UserModel } from "./models";

export class UsersService {
  private static api = new ApiService();

  static async fetchCurrentUser(): Promise<UserModel> {
    return await this.api
      .authenticated()
      .get(`/users/me`)
      .then((response) => UserModel.fromRequest(response.data));
  }

  static async updateCurrentUser(dto: UpdateUserDto): Promise<UserModel> {
    return await this.api
      .authenticated()
      .patch(`/users/me`, dto)
      .then((response) => UserModel.fromRequest(response.data));
  }
}

export default UsersService;
