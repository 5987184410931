import React, { PropsWithChildren } from "react";

import { Stack } from "../../stack";

import styles from "./styles.module.scss";

type Props = PropsWithChildren<{
  className?: string;
}>;

export function FormSection(props: Props) {
  const { className, children } = props;

  return <Stack className={[styles.container, className].filter(Boolean).join(" ")}>{children}</Stack>;
}
