import React from "react";

import { Item } from "@radix-ui/react-accordion";

import styles from "./styles.module.scss";

export const AccordionItem = React.forwardRef<
  React.ElementRef<typeof Item>,
  React.ComponentPropsWithoutRef<typeof Item> & { className?: string } // HACK
>((props, forwardedRef) => (
  <Item {...props} ref={forwardedRef} className={styles.item + " " + (props.className ?? "")} />
));

AccordionItem.displayName = "AccordionItem";

export default AccordionItem;
