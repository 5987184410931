import React, { ReactNode } from "react";

import Icon from "../../icon";

import styles from "./modal.module.scss";

export type ModalProps = {
  visible: boolean;
  title: ReactNode;
  children: ReactNode;
  closeHandler?: () => void;
};

export default function Modal(props: ModalProps) {
  const { title, children, visible, closeHandler } = props;

  if (!visible) {
    return <React.Fragment />;
  }

  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <div onClick={() => closeHandler && closeHandler()} className={styles.header}>
          <h3>{title}</h3>
          <Icon className={styles.closeIcon} name={"close"} />
        </div>

        <div className={styles.body}>{children}</div>
      </div>
    </div>
  );
}
