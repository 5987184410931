import React from "react";

import styles from "./styles.module.scss";

type ItemDetails<T> = {
  item: T;
  itemIndex: number;
  columnIndex: number;
};

type Props<T> = {
  items: T[][];
  onPick?: (pick: ItemDetails<T>) => void;
  getItemKey?: (item: T) => string;
  getItemClass?: (item: T) => string;
  getItemContent?: (item: T) => React.ReactNode;
  className?: string;
};

export default function MultiColumnPicker<T>(props: Props<T>) {
  const { items, onPick, getItemKey, getItemClass, className } = props;
  const getItemContent = props.getItemContent ?? String;

  return (
    <div className={`${styles.multiColumnPicker} ${className ?? ""}`}>
      {items.map((column, columnIndex) => (
        <div key={columnIndex} className={styles.column}>
          {column.map((item, index) => {
            const classNames = [styles.item, getItemClass?.(item)].filter(Boolean);
            const className = classNames.join(" ");
            return (
              <button
                key={getItemKey?.(item) ?? index}
                type="button"
                onClick={() => onPick?.({ item, itemIndex: index, columnIndex })}
                className={className}
              >
                {getItemContent(item)}
              </button>
            );
          })}
        </div>
      ))}
    </div>
  );
}
