import React from "react";
import styles from "./styles.module.scss";

type OptionValue = string;

type MenuItem = {
  label: string;
  value: OptionValue;
};

type Props = {
  options: MenuItem[];
  selected?: OptionValue;
  onOptionPress?: (value: OptionValue) => void;
};

export default function TabMenu(props: Props) {
  const { options, selected, onOptionPress } = props;

  return (
    <div className={styles.container}>
      {options.map((item, index) => (
        <div
          key={index}
          className={styles.item}
          data-active={item.value === selected}
          onClick={() => onOptionPress && onOptionPress(item.value)}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
}
