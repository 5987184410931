import React, { Suspense } from "react";
import { Link, Outlet } from "react-router-dom";

import useWindowDimensions from "../../../app/helpers/window/useWindowDimensions";

import AppTopNotifications from "../../modules/notifications/app.top.notifications";
import { useCurrentUserQuery, useCurrentUserRole } from "../../../modules/users/hooks";

import { DefaultLoader, Icon, InternalLink } from "../../themed";
import AppLogo, { LogoType } from "../../themed/logo";

import { Header } from "./header";
import { SidebarNav } from "./sidebar-nav";

import styles from "./layout.module.scss";

function calculateLogoType(windowWidth: number): LogoType {
  const breakpoint = Number(styles["sidebar-breakpoint"].replace("px", ""));
  return windowWidth >= breakpoint ? LogoType.Full : LogoType.LogoMark;
}

export default function AppLayout() {
  const { data: user } = useCurrentUserQuery();

  const dimensions = useWindowDimensions();
  const logoType = calculateLogoType(dimensions.width);

  const currentRole = useCurrentUserRole();
  if (!currentRole) {
    return <DefaultLoader />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.pageContent}>
        <div className={styles.sidebar}>
          <div className={styles.logoContainer}>
            <Link to={"/"}>
              <AppLogo type={logoType} />
            </Link>
          </div>

          <div className={styles.menuAndUser}>
            <SidebarNav />

            {user && (
              <div className={styles.userSection}>
                <Icon name={"account_circle"} />
                <InternalLink to="/user/profile">{user.email}</InternalLink>
              </div>
            )}
          </div>
        </div>

        <div className={styles.sectionContent}>
          <div className={styles.headerContainer}>
            <Header />
          </div>

          <div>
            <AppTopNotifications />
          </div>

          <Suspense
            fallback={
              <div className={styles.suspenseLoaderContainer}>
                <DefaultLoader className={styles.suspenseLoader} inline />
              </div>
            }
          >
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
}
