import React, { PropsWithChildren, ReactNode } from "react";

import { Stack } from "../../stack";
import { FormInstructions } from "../form-instructions";
import { FormLabel } from "../form-label";

type Props = PropsWithChildren<{
  label?: ReactNode;
  description?: ReactNode;
  className?: string;
}>;

export function FormField(props: Props) {
  const { label, description, className, children } = props;

  return (
    <Stack gap="sm" className={className}>
      {typeof label === "string" ? <FormLabel>{label}</FormLabel> : label}
      {typeof description === "string" ? <FormInstructions>{description}</FormInstructions> : description}
      {children}
    </Stack>
  );
}
