import { useQuery } from "@tanstack/react-query";

import PartnersService from "../partners.service";
import { qkForPartner } from "../query-keys";

export function usePartnerQuery(partnerId: string) {
  return useQuery({
    enabled: Boolean(partnerId),
    queryKey: qkForPartner(partnerId),
    queryFn: () => PartnersService.fetchPartner(partnerId),
  });
}
