import React from "react";

import Button from "../button";

type ExtraProps = {
  copyText: string;
};

type Props = React.ComponentPropsWithoutRef<typeof Button> & ExtraProps;

export function CopyButton(props: Props) {
  const { copyText, ...restProps } = props;

  function handleClick() {
    navigator.clipboard.writeText(copyText).catch((error) => {
      console.error("Failed to copy text to clipboard", error);
    });
  }

  return <Button {...restProps} onClick={handleClick} />;
}

export default CopyButton;
