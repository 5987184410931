import moment from "moment";
import React, { useState } from "react";

import { Overlay } from "../../overlay";
import { DatePicker } from "../date-picker";

import { AbsoluteDateRange, DateRange, RelativeDateRange } from "./date-range";
import { RelativeRangeButton } from "./relative-range-button";

import styles from "./styles.module.scss";

const dateFormat = "YYYY-MM-DD";

function toAbsoluteDateRange(range: DateRange): AbsoluteDateRange {
  if ("relativeRange" in range) {
    const nowDate = new Date();
    const now = () => moment(nowDate);

    // The range calculations below are pretty goofy; some are "true" (ie. `LastMonth` is actually calculated by
    // subtracting a month), but most are "weird" (ie. `LastSevenDays` involves subtracting 6 days??).
    //
    // I suspect some of this funkiness comes from the fact that in our graphs, sometimes days are shown, and sometimes
    // months are shown (depending on the duration selected). However, some of it may be down to wording: when you say
    // "show me last year", do you expect to see the previous 12 months (potentially spanning two separate years), or
    // all of last year (from January to December)?

    switch (range.relativeRange) {
      case RelativeDateRange.LastSevenDays:
        // 6 previous days + 1 current day
        return [now().subtract(6, "days").format(dateFormat), now().format(dateFormat)];

      case RelativeDateRange.LastMonth:
        return [now().subtract(1, "month").format(dateFormat), now().format(dateFormat)];

      case RelativeDateRange.LastThreeMonths:
        // 2 previous month + month to date
        return [now().subtract(2, "months").startOf("month").format(dateFormat), now().format(dateFormat)];

      case RelativeDateRange.LastSixMonths:
        // 5 previous months + month to date
        return [now().subtract(5, "months").startOf("month").format(dateFormat), now().format(dateFormat)];

      case RelativeDateRange.LastQuarter:
        return [
          now().subtract(1, "quarter").startOf("quarter").format(dateFormat),
          now().subtract(1, "quarter").endOf("quarter").format(dateFormat),
        ];

      case RelativeDateRange.LastYear:
        return [
          now().subtract(1, "year").startOf("year").format(dateFormat),
          now().subtract(1, "year").endOf("year").format(dateFormat),
        ];

      case RelativeDateRange.MonthToDate:
        return [now().startOf("month").format(dateFormat), now().format(dateFormat)];

      case RelativeDateRange.QuarterToDate:
        return [now().startOf("quarter").format(dateFormat), now().format(dateFormat)];

      case RelativeDateRange.YearToDate:
        return [now().startOf("year").format(dateFormat), now().format(dateFormat)];
    }
  } else {
    return range.absoluteRange;
  }
}

function getDateRangeLabel(range: DateRange): string {
  if ("relativeRange" in range) {
    switch (range.relativeRange) {
      case RelativeDateRange.LastSevenDays:
        return "Last seven days";

      case RelativeDateRange.LastMonth:
        return "Last month";

      case RelativeDateRange.LastThreeMonths:
        return "Last three months";

      case RelativeDateRange.LastSixMonths:
        return "Last six months";

      case RelativeDateRange.LastQuarter:
        return "Last quarter";

      case RelativeDateRange.LastYear:
        return "Last year";

      case RelativeDateRange.MonthToDate:
        return "Month to date";

      case RelativeDateRange.QuarterToDate:
        return "Quarter to date";

      case RelativeDateRange.YearToDate:
        return "Year to date";
    }
  } else {
    const [startDate, endDate] = range.absoluteRange;
    return `${startDate} - ${endDate}`;
  }
}

export function useHistoricalDateRangePickerInput() {
  const [range, setRange] = useState<DateRange>({ relativeRange: RelativeDateRange.LastSixMonths });
  const [isPickerOpen, setPickerOpen] = useState(false);
  const [newAbsoluteRangeStart, setNewAbsoluteRangeStart] = useState<string | null>(null);

  const [startDate, endDate] = toAbsoluteDateRange(range);

  return {
    range,
    setRange,
    isPickerOpen,
    setPickerOpen,
    newAbsoluteRangeStart,
    setNewAbsoluteRangeStart,
    startDate,
    endDate,
  };
}

type Props = ReturnType<typeof useHistoricalDateRangePickerInput>;

export function HistoricalDateRangePickerInput(props: Props) {
  const {
    range,
    setRange,
    isPickerOpen,
    setPickerOpen,
    newAbsoluteRangeStart,
    setNewAbsoluteRangeStart,
    startDate,
    endDate,
  } = props;

  function handleDateChange(dates: (Date | null)[]) {
    const newStartDate = moment(dates[0]).format(dateFormat);
    const newEndDate = dates[1] ? moment(dates[1]).format(dateFormat) : null;

    if (newEndDate) {
      setRange({ absoluteRange: [newStartDate, newEndDate] });
      setNewAbsoluteRangeStart(null);
      setPickerOpen(false);
    } else {
      setNewAbsoluteRangeStart(newStartDate);
    }
  }

  function setRelativeRange(relativeRange: RelativeDateRange) {
    setRange({ relativeRange });
    setPickerOpen(false);
  }

  return (
    <div className={styles.container}>
      <button type="button" onClick={() => setPickerOpen(!isPickerOpen)} className={styles.mainButton}>
        {getDateRangeLabel(range)}
      </button>

      <Overlay visible={isPickerOpen} onClose={() => setPickerOpen(false)}>
        <DatePicker
          large
          inline
          monthsShown={2}
          startDate={moment(newAbsoluteRangeStart ?? startDate).toDate()}
          endDate={newAbsoluteRangeStart ? null : moment(endDate).toDate()}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          selectsRange
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange={handleDateChange}
        >
          <div className={styles.relativeRangesContainer}>
            <RelativeRangeButton range={RelativeDateRange.LastSevenDays} setRange={setRelativeRange}>
              Last 7 days
            </RelativeRangeButton>
            <RelativeRangeButton range={RelativeDateRange.LastMonth} setRange={setRelativeRange}>
              Last month
            </RelativeRangeButton>
            <RelativeRangeButton range={RelativeDateRange.LastThreeMonths} setRange={setRelativeRange}>
              Last 3 months
            </RelativeRangeButton>
            <RelativeRangeButton range={RelativeDateRange.LastQuarter} setRange={setRelativeRange}>
              Last quarter
            </RelativeRangeButton>
            <RelativeRangeButton range={RelativeDateRange.LastSixMonths} setRange={setRelativeRange}>
              Last 6 months
            </RelativeRangeButton>
            <RelativeRangeButton range={RelativeDateRange.LastYear} setRange={setRelativeRange}>
              Last year
            </RelativeRangeButton>
            <RelativeRangeButton range={RelativeDateRange.MonthToDate} setRange={setRelativeRange}>
              Month to date
            </RelativeRangeButton>
            <RelativeRangeButton range={RelativeDateRange.QuarterToDate} setRange={setRelativeRange}>
              Quarter to date
            </RelativeRangeButton>
            <RelativeRangeButton range={RelativeDateRange.YearToDate} setRange={setRelativeRange}>
              Year to date
            </RelativeRangeButton>
          </div>
        </DatePicker>
      </Overlay>
    </div>
  );
}

export default HistoricalDateRangePickerInput;
