import { useAccountQuery } from "./use-account-query";
import { useCurrentAccountId } from "./use-current-account-id";

export function useCurrentAccountQuery() {
  const accountId = useCurrentAccountId() ?? "";

  return useAccountQuery(accountId);
}

export default useCurrentAccountQuery;
