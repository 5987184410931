import { EUserRoleType } from "../enums";
import { UserRole, areUserRolesEqual } from "../types";

export class UserModel {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  roles: UserRole[];
  createdAt: Date;
  updatedAt: Date;

  get fullName(): string | undefined {
    if (this.firstName || this.lastName) {
      return `${this.firstName} ${this.lastName}`.trim();
    }
  }

  get fullDisplayName(): string {
    if (this.fullName) {
      return `${this.fullName} (${this.email})`;
    } else {
      return this.email;
    }
  }

  get isAdmin(): boolean {
    return this.roles.some((role) => role.type === EUserRoleType.Admin);
  }

  ownsAccount(accountId: string): boolean {
    return this.roles.some((role) => role.type === EUserRoleType.AccountOwner && role.accountId === accountId);
  }

  hasRole(role: UserRole): boolean {
    return this.roles.some((userRole) => areUserRolesEqual(userRole, role));
  }

  static fromRequest(params: Partial<UserModel>) {
    const model = new UserModel();
    model.initProperties(params);
    return model;
  }

  protected initProperties(params: Partial<UserModel>) {
    Object.assign(this, params);

    if (params.createdAt) {
      this.createdAt = new Date(params.createdAt);
    }
    if (params.updatedAt) {
      this.updatedAt = new Date(params.updatedAt);
    }
  }
}

export default UserModel;
