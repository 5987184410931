import React from "react";
import { NavLink } from "react-router-dom";

import Icon from "../../../../themed/icon";

import styles from "./styles.module.scss";

type Props = {
  to: string;
  icon: string;
  label: string;
  className?: string;
};

export function SidebarLink(props: Props) {
  const { to, icon, label, className } = props;

  return (
    <div>
      <NavLink
        to={to}
        className={({ isActive }) =>
          `${styles.sidebarLink} ${isActive ? styles.sidebarLinkActive : ""} ${className ?? ""}}`
        }
      >
        <Icon name={icon} className={styles.icon} />
        <div className={styles.label}>{label}</div>
      </NavLink>
    </div>
  );
}

export default SidebarLink;
