import { useMutation } from "@tanstack/react-query";

import AuthService from "../auth.service";
import { ResetPasswordDto } from "../dtos";

export function useResetPasswordMutation() {
  return useMutation({
    mutationFn: (dto: ResetPasswordDto) => AuthService.resetPassword(dto),
  });
}

export default useResetPasswordMutation;
