import { useNavigate } from "react-router-dom";
import React, { ButtonHTMLAttributes, MouseEvent, ReactNode } from "react";

import styles from "./button.module.scss";
import Icon, { IconProps } from "../../icon/icon";

export type ButtonProps = Partial<Omit<ButtonHTMLAttributes<HTMLButtonElement>, "children" | "color">> & {
  children?: ReactNode;
  color?: "primary" | "secondary" | "neutral" | "delete" | string;
  size?: "default" | "small" | "tiny";
  mode?: "contained" | "outlined" | "text";
  loading?: boolean;
  iconLeftProps?: IconProps;
  iconRightProps?: IconProps;
  className?: string;
  to?: string;
};

export default function Button(props: ButtonProps) {
  const navigate = useNavigate();
  const {
    children,
    size = "default",
    mode = "contained",
    loading = false,
    disabled,
    iconLeftProps,
    iconRightProps,
    color,
    to,
    onClick,
    className = "",
    ...rest
  } = props;

  const clickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    if (to) {
      navigate(to);
    }

    onClick && onClick(e);
  };

  return (
    <button
      data-mode={mode}
      onClick={clickHandler}
      disabled={disabled}
      data-disabled={disabled}
      data-size={size}
      data-color={color}
      className={`${styles.button} ${className}`}
      {...rest}
    >
      {iconLeftProps !== undefined && <Icon className={styles.icon} {...iconLeftProps} />}
      {loading ? "Please wait..." : children}
      {iconRightProps !== undefined && <Icon className={styles.icon} {...iconRightProps} />}
    </button>
  );
}
