import { DOMAttributes, FocusEvent, RefCallback, useCallback, useEffect, useRef } from "react";

type ClickAwayListenerResult = {
  clickAwayProps: DOMAttributes<Element> & { ref: RefCallback<Element> };
};

export function useClickAwayListener(fn: (event: MouseEvent) => void): ClickAwayListenerResult {
  const fnRef = useRef(fn);
  fnRef.current = fn;

  const elRef = useRef<Element | null>(null);

  useEffect(() => {
    function handler(event: MouseEvent) {
      if (elRef.current && !elRef.current.contains(event.target as Element)) {
        fnRef.current(event);
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, [fnRef, elRef]);

  const ref = useCallback((el: Element | null) => (elRef.current = el), [elRef]);

  return {
    clickAwayProps: { ref },
  };
}

export function useEscapeListener(fn: (event: KeyboardEvent) => void) {
  const fnRef = useRef(fn);
  fnRef.current = fn;

  useEffect(() => {
    function handler(event: KeyboardEvent) {
      if (event.key === "Escape") {
        fnRef.current(event);
      }
    }
    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, [fnRef]);
}

export function useEnterListener(fn: (event: KeyboardEvent) => void) {
  const fnRef = useRef(fn);
  fnRef.current = fn;

  useEffect(() => {
    function handler(event: KeyboardEvent) {
      if (event.key === "Enter") {
        fnRef.current(event);
      }
    }
    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, [fnRef]);
}

// `useFocusWithin` is largely stolen React Aria [1].
//
// [1]: https://react-spectrum.adobe.com/react-aria/useFocusWithin.html

type FocusWithinProps = {
  onFocusWithin?: (event: FocusEvent) => void;
  onBlurWithin?: (event: FocusEvent) => void;
};

type FocusWithinResult = {
  focusWithinProps: DOMAttributes<Element>;
};

export function useFocusWithin(props: FocusWithinProps): FocusWithinResult {
  const { onFocusWithin, onBlurWithin } = props;

  const state = useRef({ isFocusWithin: false });

  const onBlur = useCallback(
    (event: FocusEvent) => {
      if (!state.current.isFocusWithin) {
        return;
      }
      // `relatedTarget` is the element that focus is moving to
      const isNewFocusStillWithin = (event.currentTarget as Element).contains(event.relatedTarget as Element);
      if (isNewFocusStillWithin) {
        return;
      }
      state.current.isFocusWithin = false;
      onBlurWithin?.(event);
    },
    [onBlurWithin, state]
  );

  const onFocus = useCallback(
    (event: FocusEvent) => {
      if (state.current.isFocusWithin) {
        return;
      }
      state.current.isFocusWithin = true;
      onFocusWithin?.(event);
    },
    [onFocusWithin, state]
  );

  return {
    focusWithinProps: {
      onBlur,
      onFocus,
    },
  };
}
