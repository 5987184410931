import { useQuery } from "@tanstack/react-query";

import AccountService from "../account.service";
import { qkForAccountEmbedCodeConfig } from "../query-keys";

import { useCurrentAccountId } from "./use-current-account-id";

export function useMyEmbedCodeConfigQuery() {
  const accountId = useCurrentAccountId() ?? "";

  return useQuery({
    queryKey: qkForAccountEmbedCodeConfig(accountId),
    queryFn: () => AccountService.fetchEmbedCodeConfig(accountId),
    enabled: Boolean(accountId),
  });
}
