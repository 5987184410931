import { useMutation, useQueryClient } from "@tanstack/react-query";

import { UpdatePartnerDto } from "../dto";
import PartnersService from "../partners.service";
import { qkForAccountPartners, qkForPartner, qkForSubPartners } from "../query-keys";

export function useUpdatePartnerMutation(partnerId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: UpdatePartnerDto) => PartnersService.updatePartner(partnerId, dto),
    onSuccess(partner) {
      queryClient.invalidateQueries(qkForAccountPartners(partner.accountId));
      queryClient.invalidateQueries(qkForPartner(partner.id));
      if (partner.parentPartnerId) {
        queryClient.invalidateQueries(qkForSubPartners(partner.parentPartnerId));
      }
    },
  });
}

export default useUpdatePartnerMutation;
