import { useMutation, useQueryClient } from "@tanstack/react-query";

import { qkForCurrentUser } from "../query-keys";
import { UpdateUserDto } from "../dtos";
import UsersService from "../users.service";

export function useUpdateCurrentUserMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: UpdateUserDto) => UsersService.updateCurrentUser(dto),
    onSuccess(user) {
      queryClient.setQueryData(qkForCurrentUser(), user);
    },
  });
}

export default useUpdateCurrentUserMutation;
