import { CommissionSettings } from "../../rewards/models/commission-settings";
import { PartnerGeneralStatsModel } from "../../stats/models";

export class PartnerModel {
  id: string;
  accountId: string;
  parentPartnerId?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  referralCode: string;
  commissionSettings?: CommissionSettings;
  approvedPayoutYears: number[];
  stats: PartnerGeneralStatsModel;
  createdAt: Date;
  updatedAt: Date;

  [key: string]: string | number | Date | unknown;

  get fullName(): string | undefined {
    if (this.firstName || this.lastName) {
      return `${this.firstName ?? ""} ${this.lastName ?? ""}`.trim();
    }
  }

  get displayName(): string {
    return this.fullName ?? this.email.split("@")[0];
  }

  get isCurrentlyApprovedForPayouts(): boolean {
    return this.approvedPayoutYears.includes(new Date().getFullYear());
  }

  isSame(other: PartnerModel): boolean {
    return this.id === other.id;
  }

  static fromRequest(params: Partial<PartnerModel>) {
    const model = new PartnerModel();
    model.initProperties(params);

    if (params.commissionSettings) {
      model.commissionSettings = CommissionSettings.fromRequest(params.commissionSettings);
    }
    if (params.stats) {
      model.stats = PartnerGeneralStatsModel.fromRequest(params.stats);
    }

    return model;
  }

  protected initProperties(params: Partial<PartnerModel>) {
    Object.assign(this, params);

    if (params.createdAt) {
      this.createdAt = new Date(params.createdAt);
    }
    if (params.updatedAt) {
      this.updatedAt = new Date(params.updatedAt);
    }
  }
}

export default PartnerModel;
