import React, { PropsWithChildren } from "react";
import styles from "./styles.module.scss";

type Props = PropsWithChildren & {
  type?: "info" | "warning" | "error" | "success";
};

export default function Alert(props: Props) {
  const { type = "info", children } = props;

  return (
    <div data-alert-type={type} className={styles.container}>
      {children}
    </div>
  );
}
