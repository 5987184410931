import { useQuery } from "@tanstack/react-query";

import PartnersService from "../partners.service";
import { qkForPartnerInvitationStatus } from "../query-keys";

export function usePartnerInvitationStatusQuery(partnerId: string) {
  return useQuery({
    enabled: Boolean(partnerId),
    queryKey: qkForPartnerInvitationStatus(partnerId),
    queryFn: async () => (await PartnersService.fetchPartnerInvitationState(partnerId)).status,
  });
}
