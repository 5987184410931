import { useMutation } from "@tanstack/react-query";

import { EUserRoleType, useCurrentUserRole } from "../../users";

import PartnersService from "../partners.service";

export function useMyPartnersCsvDownload() {
  const role = useCurrentUserRole();

  return useMutation({
    async mutationFn() {
      if (role?.type === EUserRoleType.AccountOwner) {
        await PartnersService.downloadAccountPartnersCsv(role.accountId);
      } else if (role?.type === EUserRoleType.Partner) {
        await PartnersService.downloadSubPartnersCsv(role.partnerId);
      }
    },
  });
}

export default useMyPartnersCsvDownload;
