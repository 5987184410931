import React from "react";

import styles from "./styles.module.scss";

type Props = {
  className?: string;
  children: React.ReactNode;
};

export function ErrorText(props: Props) {
  const { className, children } = props;

  return <span className={styles.errorText + " " + (className ?? "")}>{children}</span>;
}

export default ErrorText;
