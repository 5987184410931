import { useMutation } from "@tanstack/react-query";

import PartnersService from "../partners.service";

export function useResendPartnerInvitation(partnerId: string) {
  return useMutation({
    mutationFn: () => PartnersService.resendPartnerInvitation(partnerId),
  });
}

export default useResendPartnerInvitation;
