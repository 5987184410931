import React, { PropsWithChildren } from "react";

import { useEscapeListener } from "../../../app/helpers";

import styles from "./styles.module.scss";

type Props = PropsWithChildren & {
  visible: boolean; // TODO: rename `open`? Or rename `onClose()` to `onHide()`?
  onClose: () => void;
};

export function Overlay(props: Props) {
  const { visible, onClose, children } = props;

  function handleContainerClick(event: React.MouseEvent) {
    // We want to close if the container (ie. background) was clicked, not if a child of the overlay (ie. some button
    // within a card) was clicked.
    if (event.target === event.currentTarget) {
      onClose();
    }
  }

  useEscapeListener(() => {
    if (visible) {
      onClose();
    }
  });

  if (visible) {
    return (
      <div className={styles.container} onClick={handleContainerClick}>
        <div className={styles.content}>{children}</div>
      </div>
    );
  } else {
    return null;
  }
}

export default Overlay;
