import React from "react";
import ReactDOM from "react-dom/client";

import App from "./app";
import reportWebVitals from "./reportWebVitals.js";

const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(<App />);

reportWebVitals();
