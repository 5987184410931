import React from "react";
import styles from "./no.data.state.module.scss";

import EmptyState from "../empty.state";
import LoadingState, { LoadingStateProps } from "../loading.state";

type Props = {
  empty?: boolean;
  loading?: boolean;
  emptyText?: string;
  loaderProps?: LoadingStateProps;
};

export default function NoDataState(props: Props) {
  const { empty = false, loading = false, emptyText = "No data to show", loaderProps } = props;

  if (!empty && !loading) {
    return null;
  }

  return (
    <div className={styles.container}>
      {loading && <LoadingState {...loaderProps} />}
      {!loading && empty && <EmptyState text={emptyText} />}
    </div>
  );
}
