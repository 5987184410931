import { EWiseProfileType } from "../enums";

export class WiseProfile {
  id: number;
  type: EWiseProfileType;
  fullName?: string;
  businessName?: string;

  static fromRequest(details: any) {
    const profile = new WiseProfile();
    profile.id = details.id;
    profile.type = details.type;
    profile.fullName = details.fullName;
    profile.businessName = details.businessName;
    return profile;
  }
}
