import { UseQueryResult } from "@tanstack/react-query";

import { useAccountQuery } from "./use-account-query";

export function useAccountCurrencyQuery(accountId: string) {
  const accountQuery = useAccountQuery(accountId);

  return {
    ...accountQuery,
    data: accountQuery.data?.defaultCurrency,
  } as UseQueryResult<string>;
}

export default useAccountCurrencyQuery;
