import AuthSessionTokensModel from "./models/auth-session-tokens.model";

const storageKey = "ai.leadpath.app.session-state";

export function storeAuthSessionTokens(tokens: AuthSessionTokensModel | null) {
  try {
    if (tokens) {
      localStorage.setItem(storageKey, JSON.stringify(tokens));
    } else {
      localStorage.removeItem(storageKey);
    }
  } catch (error) {
    // Ignore
  }
}

export function loadAuthSessionTokens(): AuthSessionTokensModel | null {
  try {
    const jsonStr = localStorage.getItem(storageKey);
    if (jsonStr) {
      const json = JSON.parse(jsonStr);
      const tokens = new AuthSessionTokensModel();
      tokens.accessToken = json.accessToken;
      tokens.refreshToken = json.refreshToken;
      return tokens;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}
