import React from "react";

import { Button } from "../../buttons";
import { ErrorText } from "../../errors";
import Modal, { ModalProps } from "../modal";

import styles from "./styles.module.scss";

// TODO: we should probably refactor this to allow the user to pass in a custom body, rather than adding props like
// `message` and `errorMessage`.

type Props = Omit<ModalProps, "title" | "children"> & {
  resourceType: string;
  title?: ModalProps["title"];
  errorMessage?: React.ReactNode;
  deleting: boolean;
  onDelete: () => void | Promise<void>;
  onClose?: () => void;
} & ({ resourceName: string } | { message: React.ReactNode });

export default function DeleteConfirmationModal(props: Props) {
  const { resourceType, title, errorMessage, deleting, onDelete, onClose, visible } = props;

  const message =
    "message" in props ? (
      props.message
    ) : (
      <>
        This will delete <strong>{props.resourceName}</strong>. This action is permanent and cannot be undone.
      </>
    );

  const defaultTitle = `Delete ${resourceType}`;

  const handleClose = () => {
    props.closeHandler?.();
    onClose?.();
  };

  return (
    <Modal visible={visible} title={title ?? defaultTitle} closeHandler={handleClose}>
      <div className={styles.container}>
        <div className={styles.content}>
          <p>{message}</p>

          {errorMessage && (
            <p>
              <ErrorText>{errorMessage}</ErrorText>
            </p>
          )}
        </div>

        <Button size="small" mode="outlined" color="delete" disabled={deleting} onClick={() => onDelete()}>
          Delete {resourceType}
        </Button>
      </div>
    </Modal>
  );
}
