import { OptionsEnum } from "../../embed.code";
import { Options, OptionConfiguration } from "../../embed.code/models";

export default class EmbedCodeConfigModel {
  id: string;

  createdAt: Date;

  config: Options = {} as Options;

  constructor() {
    Object.values(OptionsEnum).forEach((item) => {
      this.config[item] = OptionConfiguration.fromRequest({ key: item });
    });
  }

  static fromRequest(params: Partial<EmbedCodeConfigModel>) {
    const model = new EmbedCodeConfigModel();

    Object.values(OptionsEnum).forEach((item) => {
      const itemRawData = params.config && params.config[item] ? params.config[item] : {};
      model.config[item] = OptionConfiguration.fromRequest({
        key: item,
        ...itemRawData,
      });
    });

    if (params.createdAt) {
      model.createdAt = new Date(params.createdAt);
    }

    return model;
  }

  isValid() {
    return Object.values(this.config).filter((item) => !item.isValid()).length === 0;
  }

  isUsingOnlyDefaultValues() {
    const numberOfOptions = Object.entries(this.config).length;
    return Object.values(this.config).filter((item) => item.isDefault()).length === numberOfOptions;
  }
}
