import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { qkForAllAccountData } from "../../account/query-keys";

import PartnerModel from "../models/partner.model";
import PartnersService from "../partners.service";
import { qkForAllPartnerData } from "../query-keys";

export function useDeletePartner() {
  const queryClient = useQueryClient();

  return useCallback(
    (id: PartnerModel["id"]) =>
      PartnersService.deletePartner(id).then(() => {
        queryClient.invalidateQueries({ queryKey: qkForAllAccountData() });
        queryClient.invalidateQueries({ queryKey: qkForAllPartnerData() });
      }),
    [queryClient]
  );
}

export default useDeletePartner;
