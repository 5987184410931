import React, { useContext } from "react";

import { useSignOut } from "../../../../modules/auth";
import { UserRoleContext, useCurrentUserQuery } from "../../../../modules/users";

import { UserRolePicker } from "../../../modules/users";
import { Button } from "../../../themed";

import styles from "./styles.module.scss";

export function Header() {
  const { data: user } = useCurrentUserQuery();
  const { userRole, setUserRole } = useContext(UserRoleContext);

  const signOutHandler = useSignOut();

  return (
    <div className={styles.container}>
      {user && user.roles.length > 1 && <UserRolePicker value={userRole} onChange={setUserRole} />}

      <div className={styles.flexSpacer} />

      <Button color="neutral" mode="text" size="small" iconLeftProps={{ name: "logout" }} onClick={signOutHandler}>
        Sign out
      </Button>
    </div>
  );
}

export default Header;
