import React from "react";

import { Content } from "@radix-ui/react-accordion";

import styles from "./styles.module.scss";

export const AccordionContent = React.forwardRef<
  React.ElementRef<typeof Content>,
  React.ComponentPropsWithoutRef<typeof Content> & { className?: string } // HACK
>((props, forwardedRef) => (
  <Content {...props} ref={forwardedRef} className={styles.content + " " + (props.className ?? "")} />
));

AccordionContent.displayName = "AccordionContent";

export default AccordionContent;
