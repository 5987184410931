import React, { useMemo } from "react";

import AccountModel from "../../../../modules/account/models/account.model";
import { UserRole, areUserRolesEqual } from "../../../../modules/users/types";
import { EUserRoleType } from "../../../../modules/users/enums";
import { PickerInput } from "../../../themed";
import { useCurrentUserQuery } from "../../../../modules/users/hooks";
import { useAccountsQuery } from "../../../../modules/account/hooks";

type RoleOption = {
  role: UserRole;
  label: string;
};

type AccountRolePair = {
  account?: AccountModel;
  role: UserRole;
};

function getAccountRolePairName(pair: AccountRolePair) {
  const accountName = pair.account?.displayName ?? "(Anonymous account)";

  switch (pair.role.type) {
    case EUserRoleType.Admin:
      return "Administrator";

    case EUserRoleType.AccountOwner:
      return accountName;

    case EUserRoleType.Partner:
      return `${accountName}'s partner`;

    default:
      throw new Error("unknown role type");
  }
}

function sortRoleOptions(options: RoleOption[]): RoleOption[] {
  return options.slice().sort((o1, o2) => {
    if (o1.role.type === EUserRoleType.Admin) {
      return -1;
    }
    if (o2.role.type === EUserRoleType.Admin) {
      return 1;
    }
    return o1.label.localeCompare(o2.label);
  });
}

type Props = {
  value: UserRole | null;
  onChange: (value: UserRole) => void;
  className?: string;
};

export function UserRolePicker(props: Props) {
  const { value, onChange, ...pickerProps } = props;

  const { data: user, isLoading: isLoadingUser, isError: errorLoadingUser } = useCurrentUserQuery();
  const roles = useMemo(() => user?.roles ?? [], [user]);

  const accountIds = useMemo(
    () => roles.map((role) => ("accountId" in role ? role.accountId : undefined)).filter(Boolean) as string[],
    [roles]
  );
  const { data: accounts, isLoading: isLoadingAccounts, isError: errorLoadingAccounts } = useAccountsQuery(accountIds);

  const pairs = roles.map((role) => ({
    role,
    account: "accountId" in role ? accounts?.find((account) => account.id === role.accountId) : undefined,
  }));

  const options = pairs.map((pair) => ({
    role: pair.role,
    label: getAccountRolePairName(pair),
  }));

  const sortedOptions = sortRoleOptions(options);

  const currentOption = value ? sortedOptions.find((option) => areUserRolesEqual(option.role, value)) ?? null : null;

  return (
    <PickerInput
      options={sortedOptions}
      getOptionLabel={(option) => option.label}
      value={currentOption}
      onChange={(option) => onChange(option.role)}
      isLoading={isLoadingUser || isLoadingAccounts}
      hasError={errorLoadingUser || errorLoadingAccounts}
      {...pickerProps}
    />
  );
}

export default UserRolePicker;
