import { useMutation, useQueryClient } from "@tanstack/react-query";

import AccountService from "../account.service";
import { qkForAccount, qkForAllAccounts } from "../query-keys";

export function useDisableAccountMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (accountId: string) => AccountService.disableAccount(accountId),
    onSuccess(account) {
      queryClient.invalidateQueries({ queryKey: qkForAccount(account.id) });
      queryClient.invalidateQueries({ queryKey: qkForAllAccounts() });
    },
  });
}
