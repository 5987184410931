import React from "react";

import TabMenu from "../../menus/TabMenu";
import Card from "../Card";

import styles from "./styles.module.scss";

type CardProps = React.ComponentProps<typeof Card>;
type TabMenuProps = React.ComponentProps<typeof TabMenu>;

type Props = CardProps & {
  tabs: TabMenuProps["options"];
  selectedTab: TabMenuProps["selected"];
  onTabChange: TabMenuProps["onOptionPress"];
};

export function TabbedCard(props: Props) {
  const { tabs, selectedTab, onTabChange, children, ...cardProps } = props;

  return (
    <Card {...cardProps} className={styles.tabbedCard + " " + (cardProps.className ?? "")}>
      <TabMenu options={tabs} selected={selectedTab} onOptionPress={onTabChange} />

      <div className={styles.tabContent}>{children}</div>
    </Card>
  );
}

export default TabbedCard;
