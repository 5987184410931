import React, { ButtonHTMLAttributes } from "react";

import styles from "./styles.module.scss";

export function TextButton(props: ButtonHTMLAttributes<HTMLButtonElement>) {
  const { className, ...rest } = { type: "button" as const, ...props };

  return <button {...rest} className={[styles.button, className].filter(Boolean).join(" ")} />;
}

export default TextButton;
