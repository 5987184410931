import { useContext } from "react";
import { useMutation } from "@tanstack/react-query";

import AuthService from "../auth.service";
import { AuthSessionContext } from "../contexts";
import { ChangePasswordDto } from "../dtos";

export function useChangePasswordMutation() {
  const { setAuthSessionTokens } = useContext(AuthSessionContext);

  return useMutation({
    async mutationFn(dto: ChangePasswordDto) {
      const newTokens = await AuthService.changePassword(dto);
      setAuthSessionTokens(newTokens);
    },
  });
}

export default useChangePasswordMutation;
