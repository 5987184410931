import { useQuery } from "@tanstack/react-query";

import PartnersService from "../partners.service";
import { qkForPartnerPayoutSettings } from "../query-keys";

export function usePartnerPayoutSettings(partnerId: string) {
  return useQuery({
    enabled: Boolean(partnerId),
    queryKey: qkForPartnerPayoutSettings(partnerId),
    queryFn: () => PartnersService.fetchPartnerPayoutSettings(partnerId),
  });
}
